@import 'styles/variables/colors';

.scrollBar {
  height: calc(100vh - 70px);
  width: 480px;
  overflow-x: hidden;
  transition: all 0.3s ease;
  overflow-y: auto;
  z-index: 2;
  flex-shrink: 0;

  @media screen and (max-width: 1024px) {
    background-color: $white;
    width: 100%;

    .wrapper {
      border: none;
    }

    .container {
      max-width: 1280px;
      margin: 0 auto;
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  border-right: 3px solid var(--border-color);
}

.fullWidthWrapper {
  width: 100%;

  .wrapper {
    border: none;
  }

  .container {
    max-width: 1280px;
    margin: 0 auto;
  }
}

.hide {
  width: 0;
}

.mobileOnly {
  display: none;

  @media screen and (max-width: 1024px) {
    display: flex;
  }
}

.desktopOnly {
  display: flex;

  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.hideContainer {
  display: none;
}

.exit {
  margin-left: auto;
}

.container {
  padding: 20px;
  width: 100%;
}
.mobileContainer {
  padding: 10px;
  padding-top: 20px;
  width: 100%;
}
