@import 'styles/variables/grid';
@import 'styles/variables/common';
@import 'styles/variables/colors';

p {
  margin-bottom: 1rem;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.Login {
  @media #{$breakpoint-lg-up} {
    width: 100%;
  }
}
