@import 'styles/variables/colors';

.actions {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 20px;

  .image {
    width: 160px;
  }

  @media screen and (max-width: 740px) {
    flex-direction: column;
  }

  .button {
    margin: 0;

    @media screen and (max-width: 740px) {
      margin-left: auto;
    }
  }
}
