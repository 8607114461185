.wrapper {
  display: flex;
  max-height: 90vh;
  margin-bottom: -1.5rem;

  @media only screen and (max-width: 1280px) {
    max-height: fit-content;
    $base-font-size: 10px;
  }
}

.content {
  display: flex;
  width: 100%;
  max-width: 1920px;
  width: 100%;
  align-items: flex-start;
  gap: 2rem;

  @media only screen and (max-width: 1280px) {
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: center;
  }
}

.idea {
  display: flex;
  flex-direction: column;
  max-width: 31vw;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  justify-content: flex-start;
  gap: 2rem;

  @media only screen and (max-width: 1280px) {
    width: 100%;
    max-width: 100%;
  }

  div {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    @media only screen and (max-width: 1280px) {
      flex-direction: row;
      justify-content: center;
    }

    img {
      flex-shrink: 0;
      max-width: 30vw;
      width: 100%;
      height: auto;

      @media only screen and (max-width: 1280px) {
        max-width: 50%;
      }
    }
  }

  p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #171719;

    @media only screen and (max-width: 1280px) {
      width: 50%;
      align-self: center;
      font-size: 18px;
      line-height: 20px;
    }

    @media only screen and (max-width: 1024px) {
      font-size: 14px;
      line-height: 15px;
    }
  }
}

.roy_team {
  display: flex;
  height: fit-content;
  margin-top: -1px;

  img {
    height: 90vh;
  }
}

.community {
  display: flex;
  max-width: 1080px;
  width: 100%;
  background-image: url('./about-us-2.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .text_content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
    height: 100%;
    justify-content: space-between;

    @media only screen and (max-width: 1280px) {
      padding: 0.5rem;
    }

    .logo {
      display: none;

      @media only screen and (max-width: 1280px) {
        display: flex;
        margin: 1rem 0;
      }
    }

    .title {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 90px;
      line-height: 85px;
      text-transform: uppercase;
      background: linear-gradient(158.3deg, #e33aff 0%, #0238f7 99.76%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;

      @media only screen and (max-width: 1024px) {
        display: none;
      }
    }

    .about {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 90px;
      line-height: 85px;
      text-transform: uppercase;
      color: #ffffff;

      @media only screen and (max-width: 1024px) {
        font-size: 60px;
        line-height: 50px;
      }

      @media only screen and (max-width: 750px) {
        font-size: 45px;
        line-height: 42px;
      }
    }

    .button {
      margin-top: 1rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 40px;
      max-width: 449px;
      width: 100%;
      height: 52px;
      background: #ffffff;
      border-radius: 0px 16px 16px 16px;
      font-family: 'Jost';
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 22px;
      text-align: center;
      text-transform: uppercase;
      color: #171719;
      flex: none;
      order: 0;
      flex-grow: 0;

      @media only screen and (max-width: 1024px) {
        font-size: 12px;
        line-height: 14px;
        width: 250px;
        height: 40px;
      }

      @media only screen and (max-width: 750px) {
        font-size: 9px;
        line-height: 12px;
        width: 220px;
        height: 35px;
      }
    }

    .list {
      max-width: 42rem;
      width: 100%;

      @media only screen and (max-width: 1024px) {
        margin-top: 5rem;
      }

      ul {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding-left: 2rem;

        @media only screen and (max-width: 1024px) {
          gap: 0.5rem;
        }

        li {
          font-family: 'Jost';
          font-style: normal;
          font-weight: 500;
          font-size: 35px;
          line-height: 33px;
          text-transform: uppercase;
          color: #ffffff;

          @media only screen and (max-width: 1024px) {
            font-size: 23px;
            line-height: 22px;
          }

          @media only screen and (max-width: 750px) {
            font-size: 18px;
            line-height: 17px;
          }
        }
      }
    }
  }
}

.logo {
  @media only screen and (max-width: 1280px) {
    display: none;
  }
}
