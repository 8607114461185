.burgerMap {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 15px;
  left: 15px;
  fill: white;
  stroke: white;
}

.fixedPopup {
  position: absolute;
  bottom: 10px;
  left: 10px;
}
:global(.mapboxgl-ctrl-logo) {
  // display: none !important;
}
