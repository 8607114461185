.myFinance {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  overflow-x: auto;
  overflow-y: auto;
  max-height: none;
  padding: 20px;
  background: var(--primary-bg-trans-15);
}

.wrapper {
  width: 100%;
}
