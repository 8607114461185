.wrapper {
  display: flex;
  flex-direction: column;
  max-width: 780px;
  width: 100%;
  max-height: 80vh;
  overflow: scroll;
  gap: 20px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
