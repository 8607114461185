@import 'src/styles/variables/grid';

.wrapper {
  padding: 0 20px;
}

.mobileTopWrapper {
  padding: 0;
}

.helpOffers {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  border-radius: 0 0 15px 15px;
  max-width: 1240px;
  margin: 0 auto;
  border: 2px solid var(--border-color);
  border-top: 0;
  @media screen and (max-width: 764px) {
    border-radius: 0;
  }
  @media screen and (max-width: 500px) {
    padding: 10px 15px;
  }

  @media #{$breakpoint-xl-up} {
    flex-direction: row;
  }
  .helpOffersInfo {
    width: 100%;
    margin-bottom: 10px;
    list-style-type: none;
    @media screen and (max-width: 500px) {
      padding: 0 15px;
    }
    @media #{$breakpoint-xl-up} {
      width: 52%;
    }
  }
  .wrapperButtonHelpOffers {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 450px;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 1279px) {
      max-width: 60%;
    }
    @media screen and (max-width: 800px) {
      max-width: 100%;
    }
  }
}

.mobileWrapper {
  border-radius: 0;
  border-right: 0;
  border-left: 0;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
}
