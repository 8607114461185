@import 'styles/variables/colors';

.wrapper {
  display: flex;
  width: 100%;
  max-width: 622px;
  flex-direction: column;

  .info {
    display: flex;
    gap: 10px;
    margin-top: 20px;
  }

  .green {
    color: $green-text;
    stroke: $green-text;

    path {
      stroke: $green-text;
    }
  }

  .section {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .checkBox {
    margin: 0;
    height: 100%;

    @media screen and (max-width: 624px) {
      margin: 10px 0 10px -34px;
    }
  }

  .button {
    margin: 0;
    margin-left: auto;

    @media screen and (max-width: 624px) {
      margin-top: 20px;
    }
  }
}
