@import 'shared/styles/variables/colors';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  @media only screen and (max-width: 760px) {
    gap: 0;
    flex-direction: column;
  }

  @media only screen and (max-width: 400px) {
    width: 220px;
  }
}

.button {
  margin: 0 0 0 auto;
}
