@import '../../styles/variables/grid';
@import '../../styles/variables/typography';
@import '../../styles/variables/colors';

.form-group {
  position: relative;
  margin-bottom: 0.5rem;
  padding-top: 20px;
  width: 100%;

  @media #{$breakpoint-md-up} {
    padding-top: 25px;
  }

  button {
    max-width: unset;
  }

  label {
    position: absolute;
    top: 0;
    left: 30px;
    font-size: $text-fields-label-font-size-mobile;
    line-height: $text-fields-label-line-height-mobile;
    color: $black;
    transition: all 0.4s;

    @media #{$breakpoint-md-up} {
      font-size: $text-fields-label-font-size-desktop;
      line-height: $text-fields-label-line-height-desktop;
    }
  }

  &__select,
  &.filled {
    label {
      color: $primary;
    }
  }

  .description {
    min-height: 25px !important;
    padding: 0 30px;
    font-size: $text-fields-label-font-size-mobile;
    line-height: $text-fields-label-line-height-mobile;
    color: $black;
    min-height: 1rem;
    opacity: 0;
    transition: all 0.2s;

    @media #{$breakpoint-md-up} {
      font-size: $text-fields-label-font-size-desktop;
      line-height: $text-fields-label-line-height-desktop;
    }
  }

  &.focused {
    .description {
      opacity: 0.2;
    }
  }

  &.error {
    input[type='email'],
    input[type='password'],
    input[type='text'],
    input[type='number'],
    .prefix,
    .select,
    textarea {
      border: none;
      background-color: rgba($error, 0.1);
    }
    .description {
      color: $error-text;
      opacity: 1;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__actions {
    a:not(.btn),
    span {
      margin: 0.5rem 0;
      padding: 0 1em;
      display: block;
      color: $black;
      text-align: center;
      align-self: center;
    }

    a:not(.btn) {
      padding: 0 1.5em;
    }
  }
}
