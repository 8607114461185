@import '../../styles/variables/colors';
@import '../../styles/variables/common';
@import '../../styles/variables/grid';

.Security {
  display: grid;
  scroll-behavior: smooth;
  grid-template-rows: auto auto;
  grid-template-columns: 100%;
  margin-top: 20px;

  @media #{$breakpoint-md-up} {
    grid-template-columns: auto 1fr;
  }

  h1 {
    text-align: left;
  }

  h2 {
    text-align: left;
    margin-top: 0;
    padding-top: calc(var(--header-height) + 10px);
    margin-top: calc(-1 * (var(--header-height) + 10px));
  }

  h3 {
    margin-bottom: 1rem;
  }

  p {
    margin: 1rem 0 0 0;
  }

  a {
    cursor: pointer;
  }

  main {
    .card {
      background-color: $gray-1;
      border-radius: $border-radius;
      padding: 16px 27px;
      margin-bottom: 1.5rem;

      @media #{$breakpoint-sm-only-max} {
        padding: 1rem;
      }

      display: grid;

      @media #{$breakpoint-sm-up} {
        grid-template-columns: minmax(5rem, auto) 1fr auto;
      }

      .icon {
        display: none;

        @media #{$breakpoint-md-up} {
          display: initial;
        }
      }

      p {
        margin: 0;
      }

      hr {
        border-top: 2px solid $primary;
        display: inline-block;
        width: 100%;

        @media #{$breakpoint-sm-up} {
          width: 10rem;
        }
      }

      ul {
        padding: 0;
      }
    }

    .actions {
      display: grid;
      grid-column-gap: $grid-gap;
      grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
    }
  }
}
