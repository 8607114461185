.ChatSidebarContainer {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  height: 100%;
  width: 300px;
  border-right: 3px solid var(--border-color);
  background-color: white;

  @media screen and (max-width: 764px) {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: calc(100% - 70px);
  }

  h3 {
    text-transform: uppercase;
  }

  .ChatSidebarHeader {
    display: flex;
    flex-direction: column;

    border: 3px solid #ffffff;
    padding-left: 20px;
    padding-right: 20px;

    .wrapperNavChat {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;

      & > .arrowButton {
        display: none;
        padding: 0;

        @media screen and (max-width: 764px) {
          display: flex;
        }

        button {
          margin: 0;
        }
      }
    }

    .Icons {
      display: flex;

      .Icon {
        display: flex;
        align-items: center;
        cursor: pointer;
        border: none;
        background: transparent;
        padding: 0;
      }
    }
  }

  .ChatSidebarContent {
    display: flex;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    flex-direction: column;
    border: 3px solid #ffffff;
    border-top: none;
    padding: 8px 18px 8px 20px;
  }

  .spinner {
    align-items: center;
    justify-content: center;
  }
}

.closeSidebar {
  @media screen and (max-width: 764px) {
    transform: translate(-100%, 0%);
    transition-duration: 0.3s;
  }
}

.openSidebar {
  @media screen and (max-width: 764px) {
    transform: translate(0%, 0%);
    transition-duration: 0.3s;
  }
}
