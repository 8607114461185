@import 'shared/styles/variables/grid';
@import 'shared/styles/variables/typography';
@import 'shared/styles/variables/colors';
@import 'shared/styles/variables/common';

$hover-button-color: #f4f5fd;
$button-line-height-mobile: 22px;
$border: 2px;

//default styles for all type of buttons
.button {
  padding: 10px 40px;
  display: flex;
  margin: 15px 0;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: fit-content;
  max-width: 450px;
  min-height: 55px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 16px;
  position: relative;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  font-size: $button-font-size;
  line-height: $button-line-height-desktop;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.3s ease;
  background-color: #ffffff;
  background-clip: padding-box;
  border: solid $border transparent;

  &,
  svg path {
    transition: all 0.3s ease;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -$border;
    border-radius: inherit;
    background: $gradient-primary;
  }

  //styles of primary button
  &.primary {
    color: $white;
    background-color: transparent;

    &,
    svg path {
      stroke: $white;
    }

    &:disabled {
      opacity: 0.2;
      pointer-events: none;
    }
  }

  //styles of light button
  &.light {
    color: $black;
    background-color: $white;

    & svg path {
      stroke: $black;
    }

    &:disabled {
      pointer-events: none;
      color: rgba(0, 0, 0, 0.2);

      &:before {
        opacity: 0.2;
      }
    }
  }

  //styles of danger button
  &.danger {
    background-color: $red;
    color: white;
    border: 2px solid $red;

    &:before {
      background: white;
    }

    & svg path {
      stroke: $white;
    }

    &:hover {
      color: $red;

      & svg path {
        stroke: $red;
      }
    }

    &:disabled {
      pointer-events: none;
      background-color: rgba(255, 0, 0, 0.2);
      border-color: rgba(255, 0, 0, 0.2);
    }
  }

  //styles for button if we have icon
  &.withIcon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding-right: 35px;

    & svg {
      margin: 0;
      margin-left: 40px;
    }

    &.iconLeft {
      flex-direction: row-reverse;
      padding-right: 40px;
      padding-left: 35px;

      & svg {
        margin: 0;
        margin-right: 40px;
      }
    }
  }

  //styles for button if we have ONLY icon (without text)
  &.onlyIcon {
    padding: 14px;

    & svg {
      margin: 0 !important;
      width: 24px;
      height: 24px;
    }
  }

  //styles for small size button
  &.smallSize {
    padding: 8px 20px;
    text-transform: none;
    font-size: 15px;
    line-height: $button-line-height-mobile;
    min-height: 0;
    max-width: 250px;

    &.withIcon {
      padding: 7px 17px 7px 20px;

      & svg {
        margin: 0;
        margin-left: 20px;
        max-width: 22px;
        max-height: 22px;
      }

      &.iconLeft {
        flex-direction: row-reverse;
        padding-right: 20px;
        padding-left: 15px;

        & svg {
          margin: 0;
          margin-right: 20px;
        }
      }
    }

    &.onlyIcon {
      padding: 8px;
    }
  }

  &:hover {
    background-color: transparent;
    color: $white;

    & svg path {
      stroke: $white;
    }

    &:before {
      opacity: 0.7;
    }
  }

  &:active {
    color: $black;
    background-color: $white;

    & svg path {
      stroke: $black;
    }
  }
}

//styles for Link. It needed, bc on the tag <a> we have global styles that we should rewrite them
/* a.button {
  font-family: 'Jost';

  text-decoration: none;

  &:hover {
    background-color: $hover-button-color !important;
    color: black !important;
    opacity: 1;

    svg path {
      stroke: $black !important;
    }
  }

  &.primary {
    color: $white;
  }

  .light {
    color: $black !important;

    &:hover {
      box-shadow: 0px 0px 0px 0.7px $primary inset;
    }
  }
} */

//styles for text button. We use this only in Task and we should remove it in future
.textButton {
  background: inherit;
  border: none;
  color: #4455ea;
  font-weight: 600;
  font-size: 0.8125rem;
  line-height: 1.75;
  text-align: center;
  text-transform: uppercase;
  box-sizing: border-box;
  cursor: pointer;
  min-height: 55px;
  max-width: 450px;
}
