.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1920px;
  gap: 2rem;
  padding: 1.5rem;
}

.title {
  margin: 0;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  line-height: 47px;
  text-align: start;
  text-transform: uppercase;
  color: #000000;

  @media only screen and (max-width: 1280px) {
    font-size: 40px;
    line-height: 45px;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 25px;
    line-height: 27px;
  }
}

.list {
  display: flex;
  flex-direction: column;
  padding-left: 4rem;

  li {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 35px;
    color: #000000;

    @media only screen and (max-width: 1280px) {
      font-size: 15px;
      line-height: 20px;
    }

    @media only screen and (max-width: 1024px) {
      font-size: 10px;
      line-height: 13px;
    }
  }
}
