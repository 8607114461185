@import 'src/styles/variables/colors';
@import 'src/styles/variables/common';
@import 'src/styles/mixins/scrollbar';

$size-big: 66px;
$size-small: 51px;

.name {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;

  text-align: center;
  text-transform: uppercase;
}

.OrganisationsControl {
  .scrollContainer {
    display: grid;
    justify-content: center;
    justify-items: center;
    transition: grid-gap $animation-duration, grid-template-rows $animation-duration, grid-auto-rows $animation-duration;

    .organisation {
      height: $size-big;
      width: $size-big;
      transition: height $animation-duration, width $animation-duration;

      border: 3px solid white;
      border-radius: 50%;
      background-color: white;

      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 14px;
      line-height: 18px;
    }
  }

  &.compactMode {
    .scrollContainer {
      cursor: pointer;
      grid-template-rows: 49px;
      grid-auto-rows: 34px;
      grid-gap: 0;

      .organisation {
        &:not(:first-child) {
          width: $size-small;
          height: $size-small;
        }
      }
    }
  }

  &:not(.compactMode) {
    display: grid;
    max-height: calc(calc(var(--vh, 1vh) * 100) - 100px);
    grid-gap: 20px;

    .scrollContainer {
      @include hide-scrollbar;
      grid-template-rows: $size-big;
      grid-auto-rows: $size-big;
      grid-gap: 8px;
      height: 100%;
      overflow-x: auto;

      .organisation {
        &:not(.selected) {
          opacity: 0.2;
          cursor: pointer;

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }
}

.orientationVertical {
  grid-auto-flow: row;

  &.compactMode {
    margin-bottom: 17px;
  }
}

.orientationHorizontal {
  .scrollContainer {
    grid-auto-flow: column;
    grid-template-columns: 49px;
    grid-auto-columns: 34px;
    align-items: center;
    width: fit-content;
  }

  &.compactMode {
    margin: 10px 10px;
  }
}

.scrollButton {
  height: $size-small;
  width: $size-small;
  background-color: $white;
  border-radius: 50%;
  margin: 0 auto;
  cursor: pointer;
  opacity: 1;
  transition: opacity $animation-duration;
  display: grid;
  justify-content: center;
  align-content: center;

  &:hover {
    opacity: 0.7;
  }

  &.rotate {
    transform: rotate(180deg);
  }

  &.disabled {
    opacity: 0.1;
    transition: opacity $animation-duration;
    pointer-events: none;
    cursor: auto;
  }
}
