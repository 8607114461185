@import '/src/styles/variables/common';
@import '/src/styles/mixins/scrollbar';

.Slider {
  position: relative;
  width: 100%;

  .container {
    display: flex;
    flex-wrap: nowrap;
    overflow-y: auto;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;

    &.noScroll {
      @include hide-scrollbar;
    }
  }

  .controls {
    display: inline-grid;
    grid-template-columns: 1fr 1fr;

    @media #{$breakpoint-md-only-max} {
      // transform: translateY(70%) !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & > div {
      padding: 0.3rem;
      box-sizing: border-box;
      display: inline-grid;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
    & > div:first-child {
      padding-left: 1rem;
    }
  }
}

.Slide {
  scroll-snap-align: end;
  padding: 10px;
  max-width: 100%;
  overflow: hidden;
  @media screen and (max-width: 500px) {
    padding: 0;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .readMoreWrapper {
    width: 100%;
    height: 48%;
    .readMore {
      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 15px;
      font-size: 30px;
      background: linear-gradient(92.86deg, rgba(54, 55, 68, 0.9) 3.9%, rgba(0, 0, 0, 0.66) 96.49%);
      color: white;
      font-weight: 300;
      margin: 0 10px;
      height: 100%;
    }
  }

  .twoStepWrapper {
    height: 48%;
  }
}
