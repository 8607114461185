@import '../../styles/variables/colors';
@import '../../styles/variables/common';
@import '../../styles/variables/grid';
@import '../../styles/variables/indention';

.PrivacyPolicy {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 100%;
  scroll-behavior: smooth;

  @media #{$breakpoint-md-up} {
    grid-template-columns: auto 1fr;
  }

  & > aside {
    h3 {
      @media screen and (max-width: 764px) {
        padding: 0;
      }
    }
  }

  h2 {
    text-align: left;
    margin-top: 0;
  }

  h3 {
    margin-bottom: 1rem;
  }

  p {
    margin: 1rem 0 0 0;
  }

  main {
    @media screen and (max-width: 764px) {
      padding: 0;
    }
    margin-bottom: 40px;
    ul,
    ol {
      padding-left: 1rem;
    }

    .privacyPolicy {
      li {
        margin-bottom: 1rem;
      }
    }

    .card {
      background-color: $gray-1;
      border-radius: $border-radius;
      padding: $space-mobile;

      @media #{$breakpoint-sm-only-max} {
        padding: 1rem;
      }

      @media #{$breakpoint-lg-up} {
        width: 650px;
        padding: $space-desktop;
      }

      &.cardLarge {
        @media #{$breakpoint-lg-up} {
          width: 750px;
        }
      }

      .about {
        :global(.form-group) {
          padding: 0;
        }
      }

      p {
        margin: 0;
      }

      hr {
        border-top: 2px solid $primary;
        display: inline-block;
        width: 10rem;
      }
    }

    .about {
      margin: 0;
    }

    .aboutDescription {
      opacity: 0.3;
      padding-left: 36px;
    }

    .deletingProfile {
      display: flex;
      align-items: center;
      .icon {
        width: auto;
        padding: 0.7rem 1rem;
        margin-right: 1rem;
      }
      button {
        width: auto;
        max-width: 35rem;
      }
    }
  }
}
