.wrapperCreation {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: -10px;

  @media screen and (max-width: 740px) {
    flex-direction: column;
    gap: 0;
  }
}

.iconSquare {
  margin-right: 10px;
  & path {
    fill: transparent !important;
  }
}
