@import 'styles/variables/colors';
@import 'styles/variables/common';
@import 'styles/variables/grid';

.wrapper {
  list-style: none;

  .notification {
    background-color: unset;
    border: unset;
    width: 100%;
    transition: all 0.3s ease;

    .content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 10px 0;
      margin: 0 1.5rem;
      overflow: hidden;
      column-gap: 15px;
      border-bottom: 1px solid var(--border-color);

      .textContent {
        text-align: left;
        overflow: hidden;
        width: fit-content;
        white-space: nowrap;

        .relative {
          display: flex;
          width: fit-content;
        }

        .organization {
          margin: 0;
        }

        .title {
          color: $primary;
          margin: 0;
          line-height: 20px;
          display: flex;
        }

        .time {
          line-height: 20px;
          color: $gray-3;
          opacity: 0.9;
          font-size: 13px;
        }
      }

      .icon {
        margin-left: auto;
        display: none;

        path {
          stroke: white;
        }

        &:hover {
          path {
            stroke: $primary;
          }
        }
      }
    }

    &:hover {
      background: $gradient-blur;

      .content {
        border-color: transparent;

        .textContent {
          .time {
            color: $white;
          }
        }

        .icon {
          display: flex;
        }
      }
    }
  }
}
