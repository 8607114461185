@import '../../styles/variables/colors';

.wrapperTags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  .type {
    border-color: transparent !important;

    span {
      color: $white;
    }
  }

  .tag {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    padding: 4px 18px;
    border-radius: 0px 16px 16px 16px;
    border: 2px solid $gray-2;
    color: $black;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      text-decoration: none;
    }
  }
}
