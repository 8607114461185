.wrapper {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;

  .addIcon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    width: 100%;
    height: 100%;
    cursor: pointer;

    svg {
      z-index: 10;
    }

    /* path {
          filter: drop-shadow(0 0 0.5px rgb(255, 255, 255));
        } */

    &:hover {
      path {
        transform: scale(1.1) translate(-5%, -5%);
      }
    }
  }
}
.cropper {
  width: 640px;
  height: 360px;
  overflow: hidden;
  border-radius: 16px;
  margin: 0 auto;

  @media only screen and (max-width: 740px) {
    width: 320px;
    height: 180px;
  }

  @media only screen and (max-width: 480px) {
    width: 240px;
    height: 135px;
  }
}
.image {
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  border-radius: 16px;
}
