@import 'shared/styles/variables/colors';
@import 'shared/styles/variables/common';

.wrapper {
  display: flex;
}

.emojiWrapper {
  position: absolute;
  z-index: 3;
}
