.wrapper {
  & > p {
    margin: 0;
    margin-top: 20px;
    margin-bottom: -20px;
  }
  & > div {
    margin-top: 0 !important;
    padding-top: 10px !important;
    & > div > div {
      background-color: transparent !important;
      border: none !important;
      padding: 20px 0 20px 5px !important;
    }
  }
}
