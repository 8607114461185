@import 'styles/variables/colors';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 24px 50px 24px;
  margin: 0 24px;
  border-bottom: 2px solid $gray-1;
  transition: all 0.3s ease;

  @media screen and (max-width: 600px) {
    margin: 0;
  }
}

.header {
  display: flex;
  width: 100%;
  gap: 0.5rem;
  margin-bottom: -10px;
  word-wrap: break-word;

  .imageLink {
    width: 55px;
    height: 55px;

    .image {
      flex-shrink: 0;
      border-radius: 50%;
    }
  }
}

.title {
  display: flex;
  flex-direction: column;
  width: calc(100% - 55px - 0.5rem);

  .author_name {
    color: $primary;
  }
}

.image {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  max-height: 50vh;
  object-fit: cover;
}

.content {
  margin-top: -10px;
  position: relative;
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-height: 115px;
  overflow: hidden;
  word-wrap: break-word;

  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }

  .showBtn {
    background-color: transparent;
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    cursor: pointer;
    z-index: 2;

    span {
      color: $primary;
    }

    &:hover {
      transform: scale(1.025) translate(1.25%, 1.25%);
    }
  }

  .bottomShadow {
    position: absolute;
    display: flex;
    bottom: 0;
    width: 100%;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 1) 100%);
    backdrop-filter: blur(0.75px);
    height: 25px;
    border: none;
  }
}

.visible {
  position: relative !important;
  max-height: none;
}

.actionsButton {
  position: relative;
}

.actionsIcon {
  display: flex;
  cursor: pointer;

  &:hover {
    path {
      stroke: $primary;
    }
  }
}

.actionsPanel {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 34px;
  right: 0;
  z-index: 2;
  background-color: $white;
  padding: 15px;
  border-radius: 15px 0 15px 15px;
  border: 2px solid $gray-2;
  width: fit-content;
  min-width: 190px;
  gap: 10px;

  .button {
    display: flex;
    margin: 0;
    padding: 0;
    background-color: transparent;
    color: $black;
    border: none;
    width: fit-content;
    max-width: fit-content;
    white-space: nowrap;
    gap: 10px;

    path {
      stroke: $black !important;
    }

    &:hover {
      background-color: transparent !important;
      color: $primary !important;
      transform: scale(1.025);

      path {
        stroke: $primary !important;
      }
    }
  }

  .delete {
    &:hover {
      color: $red !important;

      path {
        stroke: $red !important;
      }
    }
  }
}

.eventExtra {
  display: flex;
  justify-content: space-between;
  align-content: flex-start;

  .info {
    display: flex;
    flex-direction: column;
    gap: 10px;

    h4 {
      color: $primary;
      display: flex;
      gap: 10px;
    }
  }

  .submitButton {
    display: flex;
    height: fit-content;
    margin: 0;
  }
}
