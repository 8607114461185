.wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  overflow-x: auto;
  overflow-y: auto;
  max-height: calc(100vh - 85px);
  height: 100vh;
  gap: 20px;
}

.wrapperSpinner {
  width: 100%;
  position: fixed;

  height: 100vh;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}
