@import '/src/styles/variables/indention';
@import '/src/styles/variables/colors';

.wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  max-width: 840px;
  width: 100%;
  margin: 0 auto;
  padding-top: 20px;

  .events {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    padding: 0 20px;
    gap: 10px;

    @media screen and (max-width: 1024px) {
      padding-right: 10px;
      padding-left: 10px;
    }

    .addToGoogle {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 15px;
      margin: 10px 0 0 0;

      & > a {
        font-size: 16px;
        line-height: 22px;
      }
    }

    .eventWrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;

      .eventText {
        font-style: normal;
        font-weight: 400;
        font-size: 23px;
        line-height: 27px;

        color: #5002f7;
      }
    }
  }

  .titleWrapper {
    padding: 0 20px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 20px;
    gap: 20px;

    @media screen and (max-width: 1024px) {
      padding-right: 10px;
      padding-left: 10px;
    }

    @media screen and (max-width: 700px) {
      flex-direction: column;
    }

    .wrapperTextInfo {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      max-width: calc(100% - 75px);

      .title {
        max-width: 100%;
        word-wrap: break-word;
        margin: 0;
        text-transform: inherit;
        margin-bottom: 5px;
      }

      .section {
        text-transform: uppercase;
        color: var(--primary-color);
        cursor: pointer;
      }

      .authorName {
        color: var(--primary-color);
      }
    }

    .sectionTitle {
      width: 100%;
      text-align: left;
      text-transform: uppercase;
      color: #828ecc;
      font-size: 20px;
      line-height: 25px;
    }
  }

  .createrWrapper {
    width: 100%;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 3px solid var(--border-color);

    @media screen and (max-width: 1024px) {
      padding-right: 10px;
      padding-left: 10px;
    }

    @media screen and (max-width: 800px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }

    .infoWrapper,
    .buttonWrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
    }

    .buttonWrapper {
      @media screen and (max-width: 800px) {
        align-self: flex-end;
      }
    }

    .infoWrapper {
      .iconWrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 7px;
        flex-wrap: wrap;

        & > span {
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #171719;
        }
      }
    }

    .buttonWrapper {
      & > button {
        margin: 0;
      }
    }
  }

  .contentWrapper {
    padding: 0 20px;
    padding-top: 20px;
    max-width: 1040px;
    width: 100%;
    margin: 0 auto;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (max-width: 1024px) {
      padding-right: 10px;
      padding-left: 10px;
    }
  }

  .content {
    margin-bottom: 30px;
    font-size: 18px;
    display: flex;
    flex-direction: column;

    p {
      &:last-of-type {
        order: 2;
      }

      img {
        margin: 15px auto;
        border-radius: 15px;
        max-height: 50vh;
        object-fit: cover;
      }
    }

    ul {
      margin-left: 15px;
    }

    .inviteWrapper {
      order: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 3px solid var(--border-color);
      border-bottom: 3px solid var(--border-color);
      width: 100vw;
      margin-left: 50%;
      transform: translateX(-50%);
      padding: 10px 10px;
      margin-bottom: 20px;

      .invite {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        max-width: 840px;
        padding: 0 20px;

        .inviteButtons {
          display: flex;
          align-items: flex-end;
          justify-content: center;
          flex-direction: column;
          @media screen and (max-width: 600px) {
            align-items: center;
          }
          .inviteButtonsText {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
          }

          .inviteButton {
            width: 100%;
          }
        }

        & img {
          width: 110px;
          height: 56px;
        }

        @media screen and (max-width: 600px) {
          flex-direction: column;
          justify-content: center;
        }

        svg {
          margin-right: 50px;

          @media screen and (max-width: 500px) {
            margin-right: 0;
            width: 250px;
          }
        }
      }
    }
  }

  .contentBottom {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    @media screen and (max-width: 940px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .buttons {
      display: flex;
      width: 100%;
      flex-direction: column-reverse;
      justify-content: flex-end;

      @media screen and (max-width: 940px) {
        width: 100%;
        justify-content: flex-start;
      }

      .pageButtons {
        display: flex;
        justify-content: space-between;

        :only-child {
          margin-left: auto;
        }

        :nth-child(2) {
          margin-left: 30px;

          @media screen and (max-width: 770px) {
            margin-left: 0;
            margin-top: 20px;
          }
        }

        @media screen and (max-width: 770px) {
          flex-direction: column;

          .pageButton {
            width: 100%;
          }
        }
      }
    }

    .findError {
      all: inherit;
      display: inline;
      cursor: pointer;
      width: auto;
      align-self: flex-end;
      color: var(--primary-color);
    }
  }

  .comments {
    max-width: 840px;
    width: 100%;
    background: var(--white);
    margin-bottom: 0;
    margin-left: 0;
    transform: translate(0);
  }
}

.pointer {
  cursor: pointer;

  &:hover {
    path {
      stroke: $primary;
    }

    span {
      color: $primary !important;
    }
  }
}

.linkButton {
  padding: 7px !important;

  svg {
    margin-left: 0 !important;
  }
}
