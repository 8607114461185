@import '../../styles/variables/colors';

.isVerified {
  z-index: 1;
  .tooltip {
    position: absolute;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    opacity: 0;
    width: auto;

    background: #ffffff;
    border-radius: 10px;

    text-align: center;
    text-transform: uppercase;
    padding: 15px 15px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    box-shadow: 0px 0px 23px -4px rgba(0, 0, 0, 1);

    z-index: 1;
    left: 0;
    top: 100%;
    transition: all 0.3s ease;
    .confirmed {
      color: $red;
    }
  }
  &:hover {
    .tooltip {
      opacity: 1;
      visibility: visible;
      z-index: 1000;
    }
  }
}
