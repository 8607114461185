.wrapper {
  display: flex;
  flex-shrink: 0;
  padding-right: 8px;
  padding-left: 8px;
  flex-direction: column;

  .fileLink {
    margin-bottom: -18px;
    display: flex;

    a {
      margin-right: 8px;
    }

    svg {
      cursor: pointer;
    }
  }

  .InputWrapper {
    width: 100%;
    display: flex;
    align-items: flex-end;
    padding: 16px 0;

    .TextareaContent {
      width: 100%;
      height: 60px;
      max-height: 300px;
      padding: 14px 27px 15px;
    }

    .sendButton {
      border-start-start-radius: 0;
      border-end-start-radius: 0;
      max-width: 185px;
      height: 60px;
      margin-bottom: 0;
      margin-top: 0;
    }

    .icons {
      display: flex;
      align-items: center;
    }

    .FileIcon {
      border: none;
      background: transparent;
      cursor: pointer;
      margin-bottom: 17px;
      padding: 0 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .sendIcon {
      & > svg path {
        transition: all 0.3s ease;
        stroke: #6d78ae;
      }
    }

    .sendIcon:hover {
      & > svg path {
        stroke: #445bc2;
      }
    }

    .emojiWrapper {
      position: fixed;
      right: 40px;
      bottom: -70px;
    }
  }
}
