.wrapperVotes {
  padding: 0 30px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 50px 0;
  @media screen and (max-width: 650px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
