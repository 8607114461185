.google {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  max-width: none !important;
  margin: 0;

  cursor: pointer;

  h4 {
    font-weight: 400;
    font-size: 23px;
    line-height: 27px;
    flex: none;
    order: 1;
    flex-grow: 0;
    text-transform: none;
  }

  .image {
    display: flex;
    width: 24px;
    height: 24px;
  }
}
