@import '../../../styles/variables/colors';
@import '../../../styles/variables/common';
@import '../../../styles/variables/grid';
@import '../../../styles/variables/indention';
@import '../../../styles/typography/common';

.SuggestionForm {
  position: relative;
  background-color: $gray-1;
  display: flex;
  flex-direction: column;
  padding: $space-mobile;
  @media screen and (max-width: 764px) {
    padding: 10px;
    padding-top: 5px !important;
    & > h2 {
      margin-left: 0 !important;
    }
  }

  @media #{$breakpoint-xs-only} {
    padding-top: calc(3rem);
  }

  @media #{$breakpoint-lg-up} {
    border-radius: $border-radius;
    padding: calc(#{$space-desktop} * 1.5) calc(#{$space-desktop} * 2);
  }
}

.container {
  grid-area: container;
  display: flex;
  flex-direction: column;

  @media #{$breakpoint-md-only-max} {
    & {
      flex-direction: column;
    }

    .image {
      min-height: 200px;
      min-width: 100%;
    }
  }

  .col {
    display: grid;
    grid-template-columns: 1fr;

    @media #{$breakpoint-md-up} {
      grid-template-columns: 1fr 2fr;
    }
  }

  .imageInputsWrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
    margin-top: 10px;
    .addImageButton {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      width: 130px;
      height: 130px;
      margin-right: 30px;
      background-color: rgb(255, 255, 255);
      color: #4455ea;
      border-radius: 15px;
      border: none;
      @media screen and (max-width: 764px) {
        margin-bottom: 30px;
        height: 100px;
      }
      & > p {
        margin: 0 !important;
        line-height: 20px;
      }
    }
    .imageWrapper {
      position: relative;
      width: 230px;
      height: 130px;
      margin-right: 30px;
      @media screen and (max-width: 764px) {
        margin-bottom: 30px;
        width: 150px;
        height: 100px;
      }
      .icon {
        position: absolute;
        top: -20px;
        right: -20px;
        border: none;
        background: transparent;
        height: 25px;
        width: 25px;
        padding: 0;
        margin: 0;
        transform: rotate(45deg);
      }
      .image {
        display: block;
        width: 100%;
        background: white;
        height: 100%;
        object-fit: contain;
        object-position: center;
        border-radius: 15px;
        @media screen and (max-width: 764px) {
          object-fit: cover;
          min-width: 0;
          min-height: 0;
        }
      }
    }
  }
}

.actions {
  margin-top: 20px;
  @media screen and (max-width: 500px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
.editor {
  margin-bottom: 20px;
  margin-top: 10px;
  padding: 0 5px;
  & > div {
    border-radius: 15px 15px 0 0 !important;
    border: none !important;

    background-color: white !important;
  }
  & > div:last-child {
    border-radius: 0 0 15px 15px !important;
    border-top: 1px solid #ccc !important;
    background-color: white !important;
  }
}

.close {
  display: inline-flex;
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
}
