@import 'styles/variables/grid';
@import 'styles/variables/common';
@import 'styles/variables/colors';

.form {
  display: flex;
  flex-direction: column;

  .enter_buttons_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;

    .button_login {
      margin: 0;
    }

    .button_download {
      margin: 0;
      border: none;
      background-color: transparent;
      text-transform: none;
      color: $primary;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }

    @media screen and (max-width: 520px) {
      .button_login {
        margin-right: 0;
      }

      button {
        width: 100%;
      }
    }
  }

  .register {
    margin-right: 0px;
    color: $primary;
  }

  .forgot {
    color: $primary;
  }
}
