.answerCount {
  margin-top: 5px;
  background: transparent;
  border: none;
  color: #4455ea;
  line-height: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;

  .arrow {
    transition: all 0.3s ease;
    margin-left: 8px;
    margin-top: 2px;
    transform: rotate(180deg);
  }
  .open {
    transform: rotate(0deg);
  }
}
