@import 'styles/variables/colors';

.wrapper {
  display: flex;
  width: 100%;
  position: relative;
  height: 200px;

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: calc((100vw - 77px) * (200px / 477px));
  }

  @media screen and (max-width: 764px) {
    height: calc(100vw * (200px / 477px));
  }

  label {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $gray-2;
    width: 100%;
    height: 100%;
    cursor: pointer;

    svg {
      z-index: 1000;
    }

    &:hover {
      path {
        transform: scale(1.1) translate(-5%, -5%);
      }
    }
  }

  .cropper {
    display: flex;
    width: 477px;
    height: 200px;
    border-radius: 0;
    overflow: hidden;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    @media screen and (max-width: 1024px) {
      width: 100%;
      height: calc((100vw - 77px) * (200px / 477px));
    }

    @media screen and (max-width: 764px) {
      height: calc(100vw * (200px / 477px));
    }
  }

  .save {
    position: absolute;
    top: 50%;
    right: -50%;
  }
}
