@import './custom-scroll.scss';
@import './variables/colors';

:root {
  --gray-2: #dcdde3;
  --black: #171719;
  --white: #ffffff;
  --link-color: #{$primary};
  --border-color: #e6e8f2;
}
