@import 'styles/variables/colors';

.text {
  color: $primary;
}

.wrapper {
  display: flex;
  flex-direction: column;
  padding-top: 0;

  .container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 20px;
    border-bottom: 2px solid var(--border-color);

    .content {
      width: 100%;
      overflow: hidden;
      display: flex;
      gap: 10px;

      a {
        display: flex;
        border-radius: 50%;

        &:hover {
          opacity: 1;
        }
      }

      .image_border {
        &:hover {
          opacity: 1;
          border: 2px solid $primary;
        }
      }
    }
  }
}

.control {
  display: flex;
  cursor: pointer;
  align-items: center;
  width: fit-content;

  .title {
    display: flex;

    &:hover {
      transform: scale(1.05);
    }
  }
}
