@import 'shared/styles/variables/colors';

.side-toolbar {
  left: 0;
  position: absolute;
  display: flex;
  justify-content: space-between;
  z-index: 100;
  transform: translateX(-100%);

  @media screen and (max-width: 1460px) {
    right: 0;
    left: unset;
    transform: none;
    transform: translateY(24px);
  }
}

.side-toolbar-btn {
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    path {
      stroke: $primary;
    }
  }
}
