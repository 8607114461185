.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  word-wrap: break-word;
  word-break: normal;
}

.main {
  display: flex;
  gap: 20px;
  justify-content: space-between;

  .container {
    width: 40%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  @media (max-width: 1024px) {
    flex-direction: column;

    .container {
      width: 100%;
    }
  }
}

.double {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
}

.reverse {
  flex-direction: row-reverse;

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
  }
}
