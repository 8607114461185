.wrapper {
  display: flex;
  height: 480px;
  overflow: hidden;

  @media only screen and (max-width: 400px) {
    transform: scale(0.8);
  }

  > div {
    > :nth-child(2) {
      > :nth-child(2) {
        height: 100%;
        justify-content: center;
      }
    }

    > :nth-last-child(1) {
      display: none;
    }
  }
}
