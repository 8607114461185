@import 'styles/variables/colors';

.wrapper {
  display: flex;
  width: fit-content;
  position: relative;
  border: 2px solid $gray-2;
  border-radius: 50%;
  z-index: 2;

  label {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $gray-2;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    cursor: pointer;

    svg {
      z-index: 100;
    }

    &:hover {
      path {
        transform: scale(1.1) translate(-5%, -5%);
      }
    }
  }

  .cropper {
    display: flex;
    width: 167px;
    height: 167px;
    border-radius: 50%;
    overflow: hidden;
    align-items: center;
    justify-content: flex-start;
    position: relative;
  }

  .save {
    position: absolute;
    top: 50%;
    right: -50%;
  }
}
