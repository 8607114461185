@import 'styles/variables/colors';

.wrapper {
  display: flex;
  flex-direction: column;
  max-width: 540px;
  width: 100%;
  gap: 20px;

  .badges {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .badge {
      position: relative;
      padding: 5px;
      width: 80px;
      height: 100px;
      border-radius: 0px 8px 8px 8px;
      background-color: $gray-2;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .shadow {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        background-color: rgba(255, 255, 255, 0.65);
        justify-content: center;
        align-items: center;
        border: none;
      }

      .image {
        width: 100%;
        height: auto;
      }

      .name {
        margin: auto;
        font-family: 'Jost*';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    @media screen and (max-width: 660px) {
      flex-direction: column;
    }

    .info {
      display: flex;
      gap: 10px;
      width: calc(100% - 210px);

      @media screen and (max-width: 660px) {
        max-width: none;
        width: 100%;
      }

      .infoText {
        color: $green-text;
      }
    }

    .button {
      margin: 0;
      height: fit-content;

      @media screen and (max-width: 660px) {
        margin-left: auto;
      }
    }
  }
}
