@import 'shared/styles/variables/grid';
@import 'shared/styles/variables/common';

.KanbanColumn {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;
  width: 360px;
  flex-shrink: 0;
  background: #fff;
  border-radius: 0 15px 15px 15px;
  height: fit-content;
  border: 2px solid var(--border-color);

  @media screen and (max-width: 1024px) {
    width: 320px;
  }

  @media screen and (max-width: 764px) {
    width: 280px;
  }

  .createTask {
    padding: 8px 16px;
    flex-direction: column;
    display: flex;
    border-width: 0px 0px thin;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.12);
  }

  .KanbanColumnHeader {
    align-items: center;
    display: flex;
    padding: 8px 16px;
  }

  hr {
    margin: 0px;
    flex-shrink: 0;
    border-width: 0px 0px 2px;
    border-style: solid;
    border-color: var(--border-color);
  }

  .ColumnContainer {
    flex-grow: 1;
    min-height: 80px;
    padding: 8px 16px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &:hover {
      overflow-y: auto;
    }
  }

  .header {
    font-size: 18px;
    text-transform: uppercase;
    margin: 0;
  }
}
