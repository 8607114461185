@import '../../styles/variables/grid';

.actionButton {
  display: flex;
  align-items: center;
  justify-content: center;

  @media #{$breakpoint-md-up} {
    display: none;
  }
}

.imageWrapper {
  width: 100%;
  height: 400px;

  & > img {
    object-fit: contain;
    object-position: center;
    width: 100%;
    height: 100%;
    cursor: zoom-in;
  }
}

.votesWrapper {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: transparent;
  width: 100%;
  margin-left: 50%;
  transform: translateX(-50%);
  padding: 30px 0;
}
