.userButton {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(158.3deg, #e33aff 0%, #0238f7 99.76%);
  border-radius: 0px 16px 16px 16px;
  border: none;
  position: relative;

  .menu {
    position: absolute;
    top: calc(100% + 13px);
    right: -20px;
    padding-top: 15px;
    opacity: 1;
    z-index: 1000;
    background-color: var(--white);
    border: 3px solid var(--border-color);
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    .item {
      background: transparent;
      border: none;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;

      &:hover {
        .title {
          color: var(--primary-color);
        }
      }

      .title {
        font-size: 14px;
      }
    }
  }
}
