.tooltip {
  border-radius: 15px !important;
  padding: 8px 20px !important;
  font-family: 'Jost' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #fafafa !important;
  opacity: 1 !important;

  border: none !important;
}
