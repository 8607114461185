@import 'styles/variables/colors';

.wrapper {
  position: relative;
  display: flex;
  height: 100%;
  width: 100px;

  &:hover {
    transform: scale(1.005);
  }

  .shadow {
    position: absolute;
    border-radius: 0px 8px 8px 8px;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    background-color: rgba(255, 255, 255, 0.65);
    justify-content: center;
    align-items: center;
    border: none;

    path {
      stroke: transparent !important;
    }

    &:hover {
      /* background: $gradient-2; */
      cursor: pointer;

      path {
        stroke: $black !important;
      }
    }
  }
}

.badge {
  width: 100%;
  height: 150px;
  position: relative;
  padding-top: 30px;
  border-radius: 0px 8px 8px 8px;
  background: linear-gradient(164.12deg, #e6e8f2 0%, #c0c5dc 32.81%, #e6e8f2 65.1%, #b0b6d0 100%);
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  cursor: pointer;

  .avatar {
    display: flex;
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    justify-content: center;

    .border {
      display: flex;
      border-radius: 50%;
      padding: 2px;
      background-color: $white;
      flex-shrink: 0;
    }
  }

  .image {
    width: 64px;
    height: 64px;
    margin: auto;
  }

  .name {
    height: 45px;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    overflow: hidden;
    font-size: 11px;
    line-height: 13px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 10px 0;
    background-color: rgba(255, 255, 255, 0.5);
    border-top: 2px solid $gray-2;
  }
}
