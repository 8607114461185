.ChatSidebarContainer {
  display: flex;
  overflow-y: auto;
  height: calc(var(--vh, 1vh) * 100);
}

.front {
  height: calc(var(--vh, 1vh) * 100 - 70px);
}

* {
  font-family: 'Jost', sans-serif;
}
