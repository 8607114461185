@import 'styles/variables/colors';
@import 'react-quill/dist/quill.snow.css';

.wrapper {
  display: flex;
  border-radius: 15px;
  border: 2px solid $gray-2;
  flex-direction: column;
  width: 100%;
  z-index: 1;
}

.errorText {
  visibility: hidden;
  padding-left: 20px;
}

.error {
  border-color: $red;
  color: $red;
  visibility: visible;
}

.quill {
  border-top: 2px solid $gray-2;

  div {
    left: auto !important;
  }
}
