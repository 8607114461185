@import 'styles/variables/colors';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 40px);
  max-width: 1240px;
  padding: 10px 20px;
  margin: 20px auto 0;
  background: $gradient-4;
  border-radius: 16px;
  gap: 10px;

  @media screen and (max-width: 720px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.icon {
  display: flex;

  @media screen and (max-width: 720px) {
    display: none;
  }
}

.title {
  display: flex;
  align-items: center;
  gap: 10px;

  .titleText {
    color: $white;
  }

  @media screen and (max-width: 720px) {
    align-items: flex-start;
  }
}

.button {
  display: flex;
  margin: 0;
  flex-shrink: 0;

  @media screen and (max-width: 720px) {
    width: 100%;
    max-width: none !important;
  }
}
