@import 'shared/styles/variables/colors';

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  overflow: hidden;
  height: 27px;
  transition: height 0.3s ease;
}

.title {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  &:hover {
    .icon {
      path {
        stroke: $primary;
      }
    }
  }
}

.open {
  height: unset;
}
