@import 'styles/variables/colors';

.wrapper {
  display: flex;
  background-color: $black;
  width: 100%;
  padding: 0 1.5rem;
}

.content {
  max-width: 1920px;
  width: 100%;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}

.title {
  max-width: 80rem;
  width: 100%;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 55px;
  text-transform: uppercase;
  color: #ffffff;

  @media only screen and (max-width: 1280px) {
    font-size: 40px;
    line-height: 45px;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 25px;
    line-height: 27px;
  }
}
