@import 'styles/variables/colors';

.wrapper {
  display: flex;
  background-color: $black;
  width: 100%;
  padding: 1.5rem;
}

.content {
  max-width: 1920px;
  width: 100%;
  display: flex;
  gap: 6rem;
  padding-bottom: 3rem;

  @media only screen and (max-width: 1280px) {
    gap: 2rem;
  }

  @media only screen and (max-width: 1024px) {
    gap: 1rem;
  }
}

.title {
  margin: 3rem 0 0 -3rem;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  line-height: 47px;
  text-transform: uppercase;
  background: linear-gradient(158.3deg, #e33aff 0%, #0238f7 99.76%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  @media only screen and (max-width: 1280px) {
    font-size: 32px;
    line-height: 30px;
    margin-left: -2rem;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 25px;
    line-height: 23px;
    margin-left: -1rem;
  }
}

.list {
  display: flex;
  flex-direction: column;
  margin-left: 3rem;
  gap: 1rem;
  max-width: 54rem;
  width: 100%;

  @media only screen and (max-width: 1280px) {
    margin-left: 2rem;
  }

  @media only screen and (max-width: 1024px) {
    margin-left: 1rem;
  }

  li {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    color: #ffffff;

    span {
      color: #ffffff;
      text-transform: uppercase;
    }

    @media only screen and (max-width: 1280px) {
      font-size: 10px;
      line-height: 12px;
    }

    @media only screen and (max-width: 1024px) {
      font-size: 10px;
      line-height: 12px;
    }
  }
}

.with_padding {
  li {
    padding-top: 1rem;
  }
}
