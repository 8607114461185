.wrapper {
  margin: 0 auto;
  //add mixin for paddings
  padding: 0 20px;
  width: 100%;
  max-width: 1280px;

  @media screen and (max-width: 764px) {
    padding: 0;
  }

  .wrapperGrid {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
