.wrapper {
  display: flex;
  flex-direction: column;
  width: 480px;
  @media screen and (max-width: 700px) {
    width: auto;
  }

  .form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .stripeText {
      margin-top: 15px;
    }
    .stripeWrapper {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      justify-content: flex-start;
      @media screen and (max-width: 700px) {
        flex-direction: column;
      }
      .stripe {
        display: flex;
        width: 200px;
        align-items: center;
        justify-content: space-between;
        background: #fff;
        color: #000;
        border: 2px solid var(--primary-color);
        text-transform: uppercase;
        border-radius: 15px;
        padding: 10px 15px;
        margin-right: 15px;
        @media screen and (max-width: 700px) {
          width: 100%;
          margin-right: 0px;
          margin-bottom: 15px;
        }
      }
      .stripeImages {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        img {
          margin-right: 15px;
          @media screen and (max-width: 430px) {
            width: 30px;
          }
        }
      }
    }
    .payButton {
      margin: 0;
      width: 230px;
      @media screen and (max-width: 600px) {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 15px;
      }
    }
    .ammountWrapper {
      width: 100%;
    }
  }

  .anotherPaymentText {
    margin-top: 15px;
    font-size: 20px;
  }
  .anotherPayment {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 10px;
    @media screen and (max-width: 600px) {
      flex-direction: column;
    }

    .anotherPaymentButtons {
      margin: 0;
      margin-right: 15px;
      margin-bottom: 10px;
      width: 100%;
      max-width: inherit;
      margin-top: 10px;

      @media screen and (max-width: 600px) {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 15px;
      }

      &:hover {
        svg path {
          stroke: rgba(0, 0, 0, 0) !important;
        }
      }
    }
    .other {
      &:hover {
        svg path {
          stroke: rgb(0, 0, 0) !important;
        }
      }
    }
  }
}
