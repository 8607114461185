@import 'styles/variables/colors';
@import 'styles/variables/typography';

.wrapper {
  background-color: var(--white);
  border: 3px solid var(--border-color);
  border-top: none;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;

  .OrganizationListItem {
    & > a {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      color: inherit;
      height: 50px;
      width: 200px;
    }

    &:hover {
      cursor: pointer;
    }

    .avatar {
      width: 50px;
      justify-self: end;
      align-self: center;
      display: flex;
      justify-content: center;
      margin-right: 10px;
    }

    .title {
      width: 140px;
      margin: 0;
      font-size: $font-size-2-desktop;
      line-height: $line-height-2-desktop;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 500;
    }
  }

  .button {
    margin: 0;
    width: 200px !important;
  }
}
