.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  border: 3px solid #ffffff;
  .ChatSidebarContainer {
    display: flex;
    flex-direction: column;

    .ChatSidebarHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      background-color: linear-gradient(138.63deg, rgba(130, 142, 204, 0.15) 26.98%, rgba(198, 196, 241, 0.15) 100%);

      > h3 {
        text-align: start;
      }
      .BackIcon {
        cursor: pointer;
        border: none;
        background: transparent;
        padding: 0;
      }
    }

    li {
      display: flex;
      align-items: center;

      img {
        min-width: 42px;
      }
      h5 {
        margin-left: 8px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
  > button {
    max-width: 190px;
    display: flex;
    align-self: center;
    z-index: 1;
  }
}
.radioWrapper {
  padding-top: 0 !important;
  margin-top: 20px;
  .radio {
    background: initial !important;
    border: none !important;
  }
}

.saveButton {
  margin-bottom: 20px;
}
