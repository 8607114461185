@import '../../../styles/variables/colors';

.SpinnerContainer {
  display: inline-grid;
  .Spinner {
    border-radius: 50%;
    border-width: 3px;
    border-style: solid;
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }

  & .red {
    border-color: rgba(255, 0, 0, 0.5) rgba(255, 0, 0, 0.5) rgba(255, 0, 0, 0.5) transparent;
  }

  & .grey {
    border-color: $gray-2 $gray-2 $gray-2 $gray-1;
  }
  & .white {
    border-color: $white $white $white transparent;
  }

  & .purple {
    border-color: #6339fb #6339fb #6339fb transparent;
  }
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
