@import 'src/styles/variables/common';
@import 'src/styles/variables/grid';

.RoutingContainer {
  min-height: calc(var(--vh, 1vh) * 99);
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  transition: padding-right $animation-duration;

  @media #{$breakpoint-sm-only-max} {
    max-width: 100vw;
  }

  .close {
    position: fixed;
    top: calc(var(--header-height) + 20px);
    right: 20px;
    display: block;
    width: 32px;
    height: 32px;
    z-index: 100;

    @media screen and (max-width: 1024px) {
      right: 10px;
      top: calc(var(--header-height) + 10px);
      width: 24px;
      height: 24px;
    }
  }

  .headerRoutingContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 40px;

    @media screen and (max-width: 1024px) {
      padding: 15px 20px;
    }

    @media screen and (max-width: 740px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    .headerButtons {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 20px;
      margin-top: -5px;

      @media screen and (max-width: 740px) {
        flex-direction: column;
        width: 100%;
        margin-top: 10px;
      }

      & > a,
      & > button {
        margin: 0;

        @media screen and (max-width: 740px) {
          width: 100%;
          max-width: none;
        }
      }
    }
  }

  .wrapper {
    padding-top: var(--header-height);
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    &.withSidebar {
      @media #{$breakpoint-md-up} {
        padding-left: $sidebar-size-desktop;
      }
    }

    .wrapperFooter {
      margin-top: auto;
      padding: 10px 40px;

      @media screen and (max-width: 1024px) {
        padding: 10px 20px;
      }

      @media screen and (max-width: 600px) {
        padding: 0 10px;
      }
    }
  }
}
