@import 'shared/styles/variables/colors';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;

  @media only screen and (max-width: 740px) {
    gap: 0;
  }
}

.time {
  display: flex;
  align-items: center;
  gap: 5px;
}

.pointer {
  cursor: pointer;

  &:hover {
    h4 {
      color: $primary;
    }

    path {
      stroke: $primary;
    }
  }
}
