.wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  position: fixed;
  z-index: 10;
  background: white;
  padding: 16px;
  border-radius: 15px;
  .item {
    display: flex;
    align-items: center;
    cursor: pointer;
    svg {
      margin-right: 16px;
    }
    p {
      color: black;
    }
  }
}

.button {
  background-color: inherit;
  border: none;
  margin-top: 8px;
}
