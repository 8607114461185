@import './grid';

$border-radius: 15px;

$sidebar-size-mobile: 70px;
$sidebar-size-desktop: 80px;
$sidebar-size-expanded-desktop: 400px;

$animation-duration: 250ms;

$grid-gap: 1rem;
