@import '/src/styles/variables/common';

.container {
  position: relative;
  width: 100%;
  aspect-ratio: 1920 / 380;
}

.mobile {
  aspect-ratio: 2 / 1;
}

.imageBlock {
  position: absolute;
  opacity: 0;
  display: none;
  width: 100%;
  height: 100%;

  .button {
    position: absolute;
    right: 1.5%;
    bottom: 0;
    border-radius: 0 20px 20px 20px;

    &:hover {
      opacity: 1;
      &::before {
        opacity: 1;
      }
    }
  }
}

.appear {
  display: block;
  opacity: 0;
}

.appearActive {
  display: block;
  opacity: 1;
  transition: opacity 1s ease-in;
}

.appearDone {
  display: block;
  opacity: 1;
}

.enter {
  display: block;
  opacity: 0;
}

.enterActive {
  opacity: 1;
  display: block;
  transition: opacity 1s ease-in;
}

.enterDone {
  opacity: 1;
  display: block;
}

.exit {
  opacity: 1;
  display: block;
}

.exitActive {
  opacity: 0;
  display: block;
  transition: opacity 1s ease-in;
}

.exitDone {
  display: none;
}

.bannerStyle img {
  border-radius: 0 !important;
}
