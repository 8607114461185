@import 'styles/variables/colors';

.wrapper {
  display: flex;
  justify-content: center;
  width: calc(100% - 480px);
  height: 100%;
  overflow-y: scroll;
  transition: all 0.3s ease;
  overflow: auto;

  @media only screen and (max-width: 1024px) {
    width: 100%;
    position: absolute;
  }

  .content {
    width: 85%;
    max-width: 770px;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }
}

.fullWidthWrapper {
  width: 100%;
}

.hide {
  width: 0;
}
