@import 'styles/variables/grid';
@import 'styles/variables/common';
@import 'styles/variables/colors';

.form {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.form_group {
  padding: 0;
}

.enter_buttons_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;

  .button_login {
    min-width: 220px;
    margin: 0;
  }

  @media screen and (max-width: 520px) {
    .button_login {
      margin-right: 0;
    }

    button {
      width: 100%;
    }
  }
}

.register {
  margin-right: 0px;
  color: $primary;
}

.forgot {
  color: $primary;
}
