@import '../../styles/variables/grid';
@import '../../styles/variables/common';
@import '../../styles/variables/colors';
@import '../../styles/variables/indention';

.Main {
  display: flex;
}

.InviteColleague {
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;

  .inviteOrganisation {
    grid-column: 2 / span 1;

    margin-top: 15px;
    margin-bottom: 25px;
  }

  .content {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;

    .card {
      background-color: $gray-1;
      border-radius: $border-radius;

      & > div {
        &:first-child {
          margin-bottom: $space-desktop;
        }
      }

      .icon {
        display: none;

        @media #{$breakpoint-md-up} {
          display: initial;
        }
      }

      .info {
        @media #{$breakpoint-xl-up} {
          padding-right: 150px;
        }
      }
    }

    .linkCopied {
      background-color: $white;
      display: flex;
      flex-direction: column;

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 113px;
        height: 113px;
        border-radius: 50%;
        border: 3px solid $red;
      }

      .link {
        border-radius: $border-radius;
        background-color: $gray-1;
        padding: 1rem;

        word-break: break-all;
      }
    }
  }

  .actions {
    grid-area: actions;
    margin-top: 1rem;

    footer {
      width: 100%;
    }

    @media #{$breakpoint-lg-up} {
      display: flex;
      justify-content: space-between;
      margin: 0;
    }

    button {
      @media #{$breakpoint-sm-only-max} {
        margin-bottom: $space-mobile;
      }

      @media #{$breakpoint-md-up} {
        margin-right: $space-desktop;
      }

      @media #{$breakpoint-lg-up} {
        max-width: 35rem;
      }
    }
  }
}
