@import '../../../../styles/variables/grid';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  height: 100%;
  flex: 0 !important;

  .wrapperContent {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 100px;
    @media screen and (max-width: 764px) {
      flex-direction: column;
      margin-bottom: 50px;
    }
    & > p {
      align-self: center;
      max-width: 455px;
      @media screen and (max-width: 764px) {
        text-align: center;
      }
    }
    & > img {
      max-width: 350px;
      margin-bottom: 0;
    }
  }

  .checkbox {
    align-self: flex-end;
    padding: 0;
    overflow: visible;
    grid-column: 12 / 25;

    span {
      font-size: 18px;
    }
  }

  @media #{$breakpoint-md-only-max} {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      margin-bottom: 40px;
    }
  }
}
