@import 'styles/variables/colors';

.active {
  background-color: rgba(80, 2, 247, 0.1);
}

.wrapper {
  background-color: $gray-1;

  &:hover {
    opacity: 1;
  }
}

.wrapperCard {
  padding: 0px 15px 15px;
  border-bottom: 3px solid var(--border-color);
}

.header {
  position: relative;
  display: flex;
  align-items: center;
}

.headerText {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 20px;
}

.wallpaper {
  display: flex;
  width: 100%;
  height: 100px;
  overflow: hidden;
  align-items: center;

  img {
    width: 100%;
    height: fit-content;
  }

  .shadow {
    position: absolute;
    cursor: pointer;
    width: 100%;
    height: 200px;
    background-color: rgb(23, 23, 25, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.chatIcon {
  position: absolute;
  width: 25px;
  height: 25px;
  left: 35px;
}

.wrapperOrganizationShortInfo {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 0;
}

.organizationShortInfo {
  display: flex;
  align-items: center;
  margin-right: 29px;
}

.shortInfoTitle {
  white-space: nowrap;
  font-size: 16px;
}

.showOnMapButton {
  display: flex;
  align-items: center;
  padding: 0;
  background-color: transparent;
  border: none;
  margin: 0;
  margin-left: auto;

  .showOnMapButtonTitle {
    margin-right: 5px;
    color: var(--primary-color);
  }
}

.organizationCount {
  color: var(--primary-color);
  margin-left: 10px;
}

.mainInfoText {
  font-size: 14px;
  margin-bottom: 15px;
}
