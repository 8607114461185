@import 'styles/variables/colors';

.cardContainer {
  width: 480px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: relative;
  max-height: 150px;
  overflow: hidden;

  &:hover {
    a {
      opacity: 1 !important;
    }

    .image_border {
      border: 2px solid $primary;
    }

    .nonpermanent {
      display: flex !important;
    }
  }

  .nonpermanent {
    display: none;
  }

  @media only screen and (max-width: 1024px) {
    max-height: 155px;
    min-width: 350px;
    width: 100%;
    border-right: none;
    .nonpermanent {
      display: flex !important;

      .text {
        display: none;
      }
    }
  }
  @media only screen and (max-width: 500px) {
    min-width: 0;
  }
}

.element {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.content {
  display: flex;
  gap: 15px;
  align-items: center;
  width: fit-content;
}

.button {
  display: flex;
  cursor: pointer;
  margin: 0 5px;
  gap: 10px;
  align-items: center;

  &:hover {
    .text,
    path {
      color: $primary;
      stroke: $primary;
    }
  }
}

.controls {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;

  .control {
    display: flex;
    width: 200px;
    gap: 15px;

    .checkbox {
      width: 20px;
      height: 20px;
    }
  }
}

.hide {
  max-height: 150px;
  animation-name: animation-hide;
  animation-duration: 300ms;
  gap: 40px;

  @media only screen and (max-width: 1024px) {
    max-height: 155px;
  }
}

.show {
  max-height: fit-content;
  animation-name: animation-show;
  animation-duration: 300ms;
  gap: 20px;

  .element {
    flex-wrap: wrap;
  }
}

@keyframes animation-show {
  0% {
    max-height: 165px;
    gap: 40px;
  }

  1% {
    max-height: 165px;
    gap: 20px;
  }

  90% {
    max-height: 400px;
    gap: 20px;
  }

  100% {
    max-height: 100%;
    gap: 20px;
  }
}

@keyframes animation-hide {
  0% {
    max-height: 100%;
    gap: 20px;
  }

  1% {
    max-height: 400px;
    gap: 20px;
  }

  90% {
    max-height: 150px;
    gap: 40px;
  }

  100% {
    max-height: 150px;
    gap: 40px;
  }
}
