@import 'styles/variables/colors';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.socials {
  display: flex;
  gap: 10px;
}

.info {
  display: flex;
  gap: 10px;
  flex-wrap: nowrap !important;
  margin-bottom: 10px;
}

.green {
  svg {
    path {
      stroke: $green-text;
    }
  }

  p {
    color: $green-text;
  }
}

.add {
  display: flex;
  cursor: pointer;
  background-color: transparent;
  border: none;
  margin-top: 22px;

  &:disabled {
    cursor: auto;
    opacity: 0.5;
  }
}

.buttons {
  display: flex;
  gap: 10px;
}

.addContainer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
}
