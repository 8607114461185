.selectWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.selectButton {
  padding: 1px 6px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.title {
  font-size: 14px;
}
.active {
  color: #5002f7;
}

.selectList {
  font-size: 14px;
  padding-left: 36px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
