.doughnutWrapper {
  width: 250px;
  margin-right: 50px;
  position: relative;
  @media screen and (max-width: 650px) {
    margin-right: 0;
  }
  .doughnutCount {
    position: absolute;
    top: 15px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    font-size: 16px;
    padding-top: 1.5rem;
  }
}

.withoutLabel {
  width: 200px;
  margin-right: 0;
  .doughnutCount {
    top: 2px;
    padding-top: 0px;
  }
}
