.wrapper {
  display: flex;
  overflow: hidden;
  width: 100%;
  gap: 20px;
}

.slide {
  position: static;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  transition: all 0.3s;
}
