.wrapper {
  display: flex;
  margin-left: 18px;
  height: 100%;
  overflow-y: scroll;
  flex-direction: column-reverse;

  @media screen and (max-width: 764px) {
    max-width: 100%;
  }
}

.noMessage {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & > h4 {
    font-weight: 400;
  }
}
