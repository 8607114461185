.wrapperNavigation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  background: var(--primary-bg-trans-15);
  padding: 10px 0;
  width: 100%;
  margin-left: 50%;
  transform: translateX(-50%);
  .navigation {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    flex-wrap: wrap;
    max-width: 1920px;
    padding: 10px 45px;
    a:nth-last-child(1) {
      margin-right: 0 !important;
    }
    @media screen and (max-width: 800px) {
      padding: 5px 25px;
    }
    @media screen and (max-width: 800px) {
      padding: 5px 5px;
    }
    @media screen and (max-width: 400px) {
      flex-direction: column;
    }
    .links {
      display: inline-block;
      color: black;
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      padding: 5px 19px 1px 19px;
      border-radius: 15px;

      text-transform: uppercase;

      color: #171719;
      margin-right: 20px;

      @media screen and (max-width: 800px) {
        font-size: 14px;
        margin-right: 10px;
      }
      @media screen and (max-width: 400px) {
        margin-right: 0;
      }

      &:hover {
        opacity: 1;
      }

      &::after {
        display: block;
        content: '';
        border-bottom: solid 3px var(--primary-color);
        transform: scaleX(0);
        transition: transform 250ms ease-in-out;
        transform-origin: 100% 50%;
      }
      &:hover:after {
        transform: scaleX(1);
        transform-origin: 0% 50%;
      }
    }
    .active {
      display: block;
      background: var(--primary-color);
      color: white;
    }
  }
}
