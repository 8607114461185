.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  .buttonWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: max-content;

    & > button {
      margin: 0;
    }

    .arrow {
      position: relative;
      width: 40px;
      height: 2px;
      background-color: #5002f7;
      border-radius: 15px;

      &::after {
        content: '';
        display: block;
        position: absolute;
        background-color: #5002f7;
        width: 10px;
        height: 2px;
        right: -5.21px;
        top: 50%;
        transform: translate(-50%, -50%);
        rotate: 45deg;
        border-radius: 15px;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        background-color: #5002f7;
        width: 10px;
        height: 2px;
        right: -6.21px;
        bottom: 50%;
        transform: translate(-50%, 0);
        rotate: -45deg;
        border-radius: 15px;
      }
    }

    .disabledArrow {
      opacity: 0.2;
    }
  }
}
