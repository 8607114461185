@import 'styles/variables/colors';

.eventsInfoWrapper {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f1f3fcd9;
  padding: 10px 15px;
  margin-bottom: 1px;

  .iconArrow {
    z-index: 4;
  }

  .fullEventsInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 15px;
    gap: 5px;
    background-color: #f1f3fccb;
    backdrop-filter: blur(10px);
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 3;
  }

  &:hover {
    opacity: 1;
    & > .eventDataWrapper {
      color: $primary;
    }
  }

  .eventDataWrapper {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    color: black;
    font-size: 20px;
    font-weight: 25px;
    z-index: 2;
    .iconCalendar {
      margin-top: 1.5px;
      z-index: 2;
    }
    &:hover {
      opacity: 1;
      color: $primary;
    }
  }
}
