.wrapper {
  width: 100%;
  height: var(--header-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border-bottom: 3px solid var(--border-color);
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--white);
  z-index: 100;

  @media screen and (max-width: 1024px) {
    padding: 0 10px;
  }

  .link {
    display: flex;
  }

  .imageRoy {
    background: transparent;
    display: flex;

    & > img {
      border-radius: 0;
    }
  }

  .pageInfo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;

    & > button {
      height: 48px;
      width: 48px;
      padding: 0;
      margin: 0;
      background: transparent;
      border: none;
    }

    .title {
      font-weight: 500;
      font-size: 35px;
      line-height: 33px;
      text-transform: uppercase;
      color: #171719;
    }
  }

  .menuIcon {
    display: none;

    @media screen and (max-width: 764px) {
      display: flex;
    }
  }

  .navigation {
    display: flex;
    height: 100%;

    @media screen and (max-width: 764px) {
      display: none;
    }

    & > ul {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 30px;
      list-style-type: none;

      & > li {
        position: relative;
        height: 100%;

        & > a {
          height: 100%;
        }

        .popup {
          position: absolute;
          top: 100%;
          left: -50%;
          padding-top: 15px;
          opacity: 1;
          z-index: 1000;
          margin-top: -12px;
        }
      }

      .navItem {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 7.5px;
        z-index: 1;

        &:hover {
          & > .navIcon {
            & > svg {
              & > path {
                stroke: var(--primary-color);
              }
            }
          }

          & > .navText {
            color: var(--primary-color);
          }
        }

        .navIcon {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .navText {
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;

          text-transform: capitalize;

          color: #171719;
        }
      }

      .activeNavItem {
        & > .navIcon {
          & > svg {
            & > path {
              stroke: var(--primary-color);
            }
          }
        }

        & > .navText {
          color: var(--primary-color);
        }
      }
    }
  }
}
