@import 'shared/styles/variables/colors';
@import 'shared/styles/variables/common';

.wrapper {
  display: flex;
  border-radius: 15px;
  flex-direction: column;
  width: 100%;
  z-index: 2;
  position: relative;
}

.errorText {
  visibility: hidden;
  padding-left: 20px;
}

.error {
  border-color: $red;
  color: $red;
  visibility: visible;
}
