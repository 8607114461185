@import 'styles/variables/colors';

.wrapper {
  display: flex;
  position: relative;
  width: calc(100% + 30px);
  margin: 0 -15px;
}

.image {
  width: 100%;
  border-radius: 16px 16px 16px 16px;
  overflow: hidden;
  max-height: 50vh;
  object-fit: cover;
  display: flex;

  img {
    object-fit: cover;
    aspect-ratio: 16/9;
  }

  &:hover {
    img {
      transform: scale(1.005) translate(0.025%, 0.025%);
    }
  }
}

.button {
  background-color: transparent;
  display: flex;
  align-items: center;
  border: none;
  position: absolute;
  height: 100%;
  width: 30%;
  z-index: 1;
  padding: 0;

  &:disabled {
    display: none;
    cursor: pointer;
  }

  &:hover {
    path {
      stroke: $primary;
      stroke-width: 2;
      transform: scale(1.1) translate(-5%, -5%);
    }
  }
}

.previous {
  left: -24px;
  justify-content: flex-start;
}

.next {
  right: -24px;
  justify-content: flex-end;
}
