.loadMore {
  margin-top: 5px;
  background: transparent;
  border: none;
  color: #4455ea;
  line-height: 30px;
  display: flex;
  align-items: center;
  margin-left: 53.5px;
  @media screen and (max-width: 529px) {
    margin-left: 0;
  }
  cursor: pointer;

  .arrow {
    transition: all 0.3s ease;
    margin-left: 8px;
    margin-top: 2px;
    transform: rotate(180deg);
  }
}
