.video-container {
  width: 100%;
  position: relative;
  margin: 0;
}

.video-wrapper {
  height: fit-content;

  .content-video {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 15px;

    &.youtube {
      aspect-ratio: 16 / 9;
    }

    &.tiktok {
      width: 324px;
      aspect-ratio: 323 / 740;
    }
  }
}
