@import '../../../styles/variables/grid';
@import '../../../styles/variables/typography';
@import '../../../styles/variables/colors';

.AutoSizeTextArea {
  padding: 13px 15px !important;

  @media screen and (max-width: 764px) {
    padding: 19px 14px !important;
  }
}

.CommentSizeTextArea {
  padding: 9px 15px !important;
}

.CommentSizeTextArea,
.AutoSizeTextArea {
  position: relative;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  color: $black;
  background: white;
  border-radius: 15px;
  box-sizing: border-box;
  outline: none;
  resize: none;
}
