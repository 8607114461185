@import 'styles/variables/colors';

.title {
  font-size: 23px;
}

.quill {
  position: relative;
  border-radius: 15px;
  border: 2px solid $gray-2;
}

.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin-top: 10px;
  z-index: 3;

  .helper {
    position: absolute;
    font-weight: 500;
    font-size: 14px;
    line-height: 25px;
    z-index: 2;
    background-color: $white;
    top: -2px;
    left: 20px;
    padding: 0 5px;
    border-radius: 5px;
    display: flex;
    height: 4px;
    align-items: center;
  }
}

.editableContent {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  word-break: break-word;
}

.errorText {
  visibility: hidden;
  padding-left: 20px;
}

.error {
  border-color: $red;
  color: $red;
  visibility: visible;
}
