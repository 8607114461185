.wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  .title {
    color: rgb(23, 43, 77);
    font-weight: 600;
    font-size: 1.0938rem;
  }

  .content {
    align-items: center;
    display: flex;
    flex-grow: 1;
    margin-bottom: 16;
  }
  .expandSection {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
}
