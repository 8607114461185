@import 'styles/variables/colors';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 2px solid var(--border-color);
  height: 480px;
  overflow-y: auto;
  padding: 15px;
  align-items: flex-start;

  h4 {
    text-transform: capitalize;
  }

  .image {
    display: flex;
    flex-shrink: 0;
  }

  .link {
    display: flex;

    &:hover {
      opacity: 1;

      .image {
        border: 2px solid $primary;
      }

      h4 {
        transform: scale(1.05);
      }
    }
  }
  .description {
    overflow: auto;
    max-height: 200px;
  }
  .footerWrapper {
    border-top: 1px solid var(--border-color);
    padding-top: 15px;
    width: 100%;
  }
}

.mobileWrapper {
  border-right: none;
  border-left: none;
}
