.content-editor {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: visible;
  width: 100%;

  strong {
    font-weight: bold;
  }

  & > .wrapperTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;
    background: linear-gradient(138.63deg, rgba(130, 142, 204, 0.15) 26.98%, rgba(198, 196, 241, 0.15));
    width: 100vw;
    margin-left: 50%;
    -webkit-transform: translateX(-24%);
    transform: translateX(-24%);
    padding: 0 20px;

    & > .title-input {
      max-width: 980px;
      font-family: inherit;
      font-size: 35px;
      line-height: 50px;
      outline: 0px none transparent;
      width: 100%;
      height: auto;
      padding: 10px 0;
      border: none;
      overflow: auto;
      resize: none;
      background: transparent;
    }
  }

  & > .adminWrapperTitle {
    width: 100%;
    -webkit-transform: translateX(-25%);
    transform: translateX(-25%);
  }
}

.wrapperSpinner {
  width: 100%;
  position: fixed;

  height: 100vh;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000027;
}

.frontWrapperSpinner {
  margin-top: -8%;
  width: 100vw;
}

.editor-container {
  position: relative;
  width: inherit;
  max-width: 1060px;
  padding: 0 30px;
}

.editor {
  width: 100%;
}

.content-editor * {
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
}

.content-editor a {
  white-space: pre-wrap !important;
  word-wrap: break-word !important;
}

.content-editor p::before {
  content: attr(data-placeholder);
  position: absolute;
  font-family: Jost;
  font-style: italic;
  font-weight: normal;
  color: #000000;
  opacity: 0.6;
}

.content-editor iframe {
  width: 100%;
  height: 500px;
}

.content-editor img {
  width: auto;
  display: block;
  border-radius: 15px;
  margin: 0 auto;
  max-height: 50vh;
}

.content-editor p {
  margin-bottom: 0.9rem !important;
}

.content-editor div[contenteditable='true'] {
  overflow-y: visible !important;
}
