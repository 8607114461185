@import 'styles/variables/colors';

.wrapper {
  display: flex;
  justify-content: center;
  width: calc(100% - 480px);
  height: 100%;
  overflow-y: scroll;
  transition: all 0.3s ease;
  overflow: auto;

  @media only screen and (max-width: 1024px) {
    width: 100%;
    position: absolute;
  }

  .content {
    width: 85%;
    max-width: 770px;
    height: 100%;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: 768px) {
      width: 95%;
    }

    .tabs {
      margin: 30px 48px;

      @media screen and (max-width: 600px) {
        margin: 0;
      }
    }
  }
}

.tgFeed {
  color: #6339fb;
  display: flex;
  align-self: flex-end;
  margin-right: 48px;
  cursor: pointer;
}

.fullWidthWrapper {
  width: 100%;
}
