.mainDropdownButton {
  background-color: white;
  border-radius: 0px 16px 16px 16px;
  padding: 10px;
  display: flex;
  align-items: flex-start;
  border: 2px solid white;
}

.reversed {
  flex-direction: row-reverse;
}

.content {
  flex: 1 1 auto;
  display: flex;
}

.icon {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(180deg);
}

.active {
  transform: rotate(0);
}

.content {
  flex: 1 1 auto;
}
