.side-toolbar {
  left: -60px;
  position: absolute;
  width: 60px;
  display: flex;
  justify-content: space-between;
  z-index: 100;
}
.side-toolbar-btn {
  border: none;
  background: none;
  cursor: pointer;
}
@media screen and (max-width: 1460px) {
  .side-toolbar {
    margin: 0 auto;
    right: 0;
    left: 0;
  }
}
