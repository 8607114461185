.wrapper {
  > h3 {
    text-align: center;
  }
}

.buttons {
  display: flex;
  justify-content: center;
  @media screen and (max-width: 570px) {
    flex-direction: column;
    align-items: center;
  }

  > :nth-child(2) {
    margin-left: 20px;
    @media screen and (max-width: 570px) {
      margin: 0 auto;
      margin-top: 10px;
    }
  }
}
