@import '../../../styles/variables/colors';

svg {
  display: inline-block;
  flex-shrink: 0;

  &.primary path {
    stroke: $primary;
  }

  &.secondary path {
    stroke: $secondary;
  }

  &.disabled path {
    stroke: $icon-disabled;
  }

  &.black path {
    stroke: black;
  }

  &.white path {
    stroke: #fff;
  }
}
