@import 'styles/variables/colors';

.card {
  border-radius: 0 30px 30px 30px;
  transition: 0.3s all ease-in-out;

  &:hover {
    opacity: 1;
    cursor: pointer;
    transform: scale(0.98);
    .title {
      color: $primary;
    }
  }

  &:focus {
    opacity: 1;
  }
}

.title {
  transition: 0.3s all ease-in-out;
}

.newButtonStyle {
  border-radius: 20px 0 20px 20px;
  margin: 0;
  padding: 6px 20px !important;
}

.newIconStyles {
  border: 2px solid $primary;
  border-radius: 0 20px 20px 20px;
  padding: 4px;
  width: 36px;
  height: 36px;
  background-color: $white;
  & path {
    stroke: $black !important;
  }
}

.innerContentWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 16px;
  background-color: #f1f3fc;
  border-radius: 0 0 30px 30px;

  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
}

.imageWrapper {
  border-radius: 0 30px 0 0;
  position: relative;

  .image {
    width: 100%;
    aspect-ratio: 1.78 / 1;
    object-fit: cover;
    border-radius: 0 30px 0 0;
  }

  .organizationLink {
    position: absolute;
    top: 15px;
    right: 15px;
  }
}
