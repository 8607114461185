@import 'styles/variables/colors';
@import 'styles/variables/common';
$border-size: 2px;

.postFieldWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  border-radius: 16px;
  margin: 0 48px;
  transition: all 0.3s ease;
  transition: border 0s;

  @media screen and (max-width: 600px) {
    margin: 0;
  }

  @media screen and (max-width: 765px) {
    padding: 10px;
    margin-top: 20px;
  }

  .postField {
    display: flex;
    width: 100%;
    gap: 20px;
  }

  .attachedImages {
    display: flex;
    padding-top: 0;
    gap: 5%;
    row-gap: 15px;
    flex-wrap: wrap;
    width: 100%;
  }
}

.active {
  padding: 14px;
  border: 2px solid #e6e8f2;
}

.button {
  display: flex;
  flex-shrink: 0;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 8px !important;
  background-color: #ffffff !important;
  border-radius: $border-radius !important;
  background-clip: padding-box !important;
  border: solid $border-size transparent !important;
  width: 100% !important;
  height: fit-content !important;
  margin: 0;

  &:hover {
    background-color: rgba(255, 255, 255, 0.5) !important;
    color: $white !important;

    & svg path {
      stroke: $white !important;
    }
  }

  &:active {
    color: $black !important;
    background-color: $white !important;

    & svg path {
      stroke: $black !important;
    }
  }
}

.quillWrapper {
  border: none;
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
  justify-content: flex-end;
  transition: all 0.3s ease;
  flex-shrink: 0;
  width: 100%;
  max-height: 305px;
}

.quillEditor {
  border-radius: 15px !important;
  border: none;
  background: rgba(80, 2, 247, 0.05);
  transition: all 0.3s ease;
  width: calc(100% - 105px);
}

.toolbar {
  width: 95px;
  flex-shrink: 0;
  border: none !important;
  border-bottom: none !important;
  gap: 5px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 0 !important;
  position: relative;
  min-height: 95px;
  transition: all 0.3s ease;
}

.fullWidth {
  flex-shrink: 0;
  height: fit-content;
  width: 100%;
}

.minHeight {
  max-height: 43px;
  overflow: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
}
