.wrapper {
  display: flex;
  width: 100%;
  margin-bottom: 8px;
  height: fit-content;
  cursor: pointer;
  line-height: 30px;
  padding-right: 16px;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    width: 100%;
  }

  time {
    color: #6339fb;
    margin-top: 8px;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
  }

  .avatar {
    margin-right: 8px;
    min-width: 42px;
  }

  .messageContent {
    max-width: 500px;
    padding: 8px;
    word-break: break-word;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 12px;
    background: linear-gradient(158.3deg, #c03aff 0%, #0238f7 99.76%);
    color: white;

    a {
      color: blue;
    }

    .repliedContent {
      background-color: #f5f6fa;
      color: #000000;
      padding: 8px;
      border-radius: 12px;
      width: fit-content;
    }
  }

  .messageContentContact {
    background: linear-gradient(138.63deg, rgba(130, 142, 204, 0.15) 26.98%, rgba(198, 196, 241, 0.15) 100%);
    color: #000000;
  }

  .messageInfo {
    display: flex;
    flex-direction: column;
    margin: 0 8px;

    @media screen and (max-width: 1024px) {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.3rem;
      margin: 0;
      margin-left: 55px;
      & > time {
        margin-top: 0;
      }
    }

    .seen {
      font-weight: 500;
      font-size: 10px;
      line-height: 20px;
      color: #6339fb;
    }
  }
}

.mineMessage {
  justify-content: flex-end;

  @media screen and (max-width: 1024) {
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.hide {
  visibility: hidden;
}
