.languageSelect {
  position: absolute;
  top: 0;
  right: 0;
}

.selected {
  margin-top: 15px;
  margin-right: 3.13vw;
  padding: 9px 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
  background: #ffffff;
  border-radius: 0 16px 16px 16px;
  border: 2px solid #e6e8f2;
  font-family: 'Jost', sans-serif;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #212427;
  cursor: pointer;
  @media only screen and (max-width: 1280px) {
    margin-right: 7.81vw;
  }
}

.options {
  display: flex;
  gap: 20px;
  padding: 15px 17px;
  background: #ffffff;
  border-left: 2px solid #e6e8f2;
  border-bottom: 2px solid #e6e8f2;
  font-family: 'Jost', sans-serif;
  font-size: 15px;
  color: #171719;
}

.crossIcon {
  font-size: 22px;
  font-weight: 500;
  color: #171719;
  cursor: pointer;
}

.languages {
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.option {
  cursor: pointer;
}

.active {
  color: #5002f7;
}
