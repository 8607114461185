$primary: #6339fb;
//$primary: #ee1c29;
$secondary: #9391c0;

$white: #ffffff;
$black: #171719;
$gray-1: #f5f6fa;
$gray-2: #dcdde3;
$gray-3: #93959d;
$gray-4: #535357;
$gray-5: #292d32;
$red: #ee1c29;
$sky-blue: #e4e6f3;
$green-text: rgba(78, 171, 149, 1);

$error: $red;
$text-field-bg: #f5f5fa;
$error-text: $red;

$gradient-1: linear-gradient(90deg, rgba(130, 142, 204, 0.2) 0%, rgba(130, 142, 204, 0) 100%);
$gradient-2: linear-gradient(344.16deg, #6339fb 0%, rgba(130, 142, 204, 0) 94.36%);
$gradient-3: linear-gradient(138.63deg, rgba(130, 142, 204, 0.15) 26.98%, rgba(198, 196, 241, 0.15) 100%);
$gradient-4: linear-gradient(158.3deg, #c03aff 0%, #0238f7 99.76%);
$gradient-5: linear-gradient(
    135.28deg,
    rgba(239, 18, 243, 0.08) 2.82%,
    rgba(213, 109, 230, 0.08) 47.69%,
    rgba(32, 128, 216, 0.08) 95.45%
  ),
  rgba(255, 255, 255, 0.002);
$gradient-blur: linear-gradient(
  134.75deg,
  rgba(18, 41, 243, 0.6) 0%,
  rgba(177, 109, 230, 0.6) 48.44%,
  rgba(179, 32, 216, 0.6) 100%
);
$gradient-primary: linear-gradient(158.3deg, #e33aff 0%, #0238f7 99.76%);

$custom-scroll-color: #2d2d93;
$icon-disabled: #dadada;
$input-disabled-border-color: #5353571a;

$spinner-color: rgba(238, 28, 41, 0.5);

// calendar
$calendar-selected-day-color: rgba(255, 0, 0, 0.8);

// landing
// $l 1: #6339fb;
$l-2_gradient: linear-gradient(158.3deg, #c03aff 0%, #0238f7 99.76%);

$l-3_gradient: linear-gradient(224.37deg, #ee1c29 14.47%, #cd1a26 85.31%);
$l_4_purple: #f5f6fa;
$l_3_black: #171719;
// $l 6: #DCDDE3;
// $l 7: #EE1C29;
$l_8_white: #ffffff;
