@import 'shared/styles/variables/colors';

.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin-top: 10px;
}

.input {
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 11px 23px;
  border-radius: 15px;
  position: relative;
  border-radius: 15px;
  border: 2px solid $gray-2;
}

.toggleSowPassword {
  display: flex;
  position: absolute;
  right: 20px;
  top: 11px;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  &:hover {
    path {
      transform: scale(1.05) translate(-2.5%, -2.5%);
    }
  }
}

.helper {
  position: absolute;
  user-select: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  z-index: 2;
  background-color: $white;
  top: -2px;
  left: 20px;
  padding: 0 5px;
  border-radius: 5px;
  display: flex;
  height: 4px;
  align-items: center;
}

.errorText {
  visibility: hidden;
  padding-left: 20px;
}

.error {
  border-color: $red;
  color: $red;
  visibility: visible;
}
