.wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;

  .questionTitleWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 10px;
    background: transparent;
    padding: 10px 0;
    width: 100%;
    margin-left: 50%;
    transform: translateX(-50%);
  }

  .wrapperQuestion {
    max-width: 1040px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    & > h2 {
      font-size: 30px;
      margin: 10px 0;
    }

    .completedPoll {
      text-align: center;
    }

    .imageWrapper {
      width: fit-content;
      height: 300px;
      margin: 0;

      & > img {
        object-fit: contain;
        object-position: center;
        width: 100%;
        height: 100%;
        cursor: zoom-in;

        border-radius: 15px;
      }
    }

    .wrapperButton {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 25px;

      & > span {
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;

        color: #000000;
      }
    }
  }
}
