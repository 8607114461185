.newsOrganisationAvatar {
  background: transparent;
  border: none;
  padding: 0;
  width: 54px;
  height: 54px;
  position: absolute;
  top: 35px;
  left: 35px;
  z-index: 10;
  transition: all 0.3s ease;
  cursor: pointer;
  &:hover {
    opacity: 1;
    transform: scale(1.1);
  }
  @media screen and (max-width: 600px) {
    top: 15px;
    left: 15px;
  }
}
