@import 'styles/variables/grid';
@import 'styles/variables/common';
@import 'styles/variables/colors';

.wrapper {
  display: flex;
  margin-top: auto;
  align-items: center;
  justify-content: space-evenly;
  height: fit-content;

  @media only screen and (max-width: 1280px) {
    flex-direction: column;
    gap: 40px;
    padding: 20px;
  }

  .container {
    display: flex;
    justify-content: center;
    width: 40%;

    @media only screen and (max-width: 1280px) {
      width: 100%;
    }

    .content {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 470px;
    }

    .info {
      width: 450px;

      @media only screen and (max-width: 1280px) {
        width: fit-content;
      }

      .blue {
        color: $primary;
      }
    }
  }
}
