@import 'shared/styles/variables/colors';
@import 'shared/styles/variables/grid';

.box_shadow {
  display: flex;
  width: 100vw;
  min-height: 100vh;
  position: fixed;
  background: $gradient-blur;
  backdrop-filter: blur(5px);
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  z-index: 101;
  overflow-y: auto;
  cursor: pointer;

  &:hover {
    & line {
      stroke: var(--primary-color);
    }
  }
}

.scroll_container {
  position: absolute;
  display: flex;
  top: 0;
  padding: 4rem 0;
  height: fit-content;
  min-height: 100vh;
  width: fit-content;
  align-items: center;

  @media only screen and (max-width: 1024px) {
    max-width: calc(100% - 40px);
  }
}

.modal_wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $white;
  z-index: 100;
  height: fit-content;
  border: 1px solid $white;
  border-radius: 16px;
  padding: 1.5rem;
  gap: 1rem;

  &:hover {
    cursor: auto;

    & line {
      stroke: $white;
    }
  }
}

.close_btn {
  position: absolute;
  top: -32px;
  right: -32px;

  @media only screen and (max-width: 1024px) {
    top: -32px;
    right: 0;
  }

  line {
    stroke: $white;
  }
}
