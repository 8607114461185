@import './../../../../styles/variables/colors';

.wrapper {
  max-width: 274px;
  min-height: 105px;
  background-color: $gray-1;
  background-color: white;
  border-top-left-radius: 0;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 23px 16px;
  display: flex;
  flex-direction: column;
  &:hover {
    opacity: 1;
  }
}

.header {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  padding-bottom: 10px;
}

.headerIcon {
  flex: 0 0 auto;
}

.headerText {
  word-wrap: break-word;
  word-break: break-all;
  margin-left: 20px;
  font-size: 20px;
  line-height: 1;
  text-transform: to-upper-case($string: '');
}
//TODO: add truncation for description
.description {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 auto;
  padding-bottom: 10px;
}

.shortInfo {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
}

.organizationShortInfo {
  display: flex;
  align-items: center;
  flex: 0;
  margin-right: 20px;
  &:last-of-type {
    margin-right: 0;
  }
}

.shortInfoTitle {
  white-space: nowrap;
  flex: 0 0 auto;
  font-size: 14px;
}

.organizationCount {
  color: #5002f7;
  margin-left: 10px;
  font-size: 16px;
}
