@import 'styles/variables/colors';

.toggleButton {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  background: transparent;
  transition: all 0.3s ease;

  svg {
    fill: white;
  }

  @media screen and (max-width: 1024px) {
    right: 0;
    left: auto;
    transform: rotate(180deg);
  }
}

.openButton {
  left: 0;

  @media screen and (max-width: 1024px) {
    right: 0;
    left: auto;
    transform: rotate(180deg);
  }
}
