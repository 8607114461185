.wrapper {
  display: flex;
  width: 100%;
  white-space: nowrap;

  .scrollable {
    display: inline-flex;
    transition: all 0s;
  }
}
