@import 'src/styles/variables/grid';

.SocialNetworks {
  width: 240px;
  display: flex;
  justify-content: space-between;
  margin-left: auto;

  .social_icons__link {
    text-decoration: none;
  }
  img {
    width: 20px;
    height: 20px;
  }
}

@media #{$breakpoint-md-only-max} {
  .SocialNetworks {
    max-width: 240px;
    width: 30%;
    display: flex;
    justify-content: space-between;

    .social_icons__link {
      text-decoration: none;
    }
  }
}
@media #{$breakpoint-sm-only-max} {
  .SocialNetworks {
    max-width: 270px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 70px;
    order: 2;
    margin-left: 0;
    .social_icons__link {
      text-decoration: none;
    }
  }
}
