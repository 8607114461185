.commentTextareaWrapper {
  width: 100%;
  max-width: 1080px;
  padding: 0 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 20px 0 10px 0;
  .imageComment {
    margin-right: 15px;
    margin-top: 3px;
    min-width: 37.5px;
  }
  .textareaWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .textareaEmojiWrapper {
      width: 100%;
      display: flex;
      align-items: center;
      position: relative;
      .textarea {
        overflow: hidden;
        padding: 9px 15px;
        margin: 0;
        border: 2px solid #6339fb;
        font-size: 14px;
        @media screen and (max-width: 756px) {
          height: 36px;
        }
      }
      .fileIcon {
        border: none;
        background: transparent;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .emojiWrapper {
        position: absolute;
        right: 35px;
        bottom: -100px;
        z-index: 1000;
      }
    }

    .wrapperButtons {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0 0 0;
      position: relative;
      & > button {
        margin: 0;
        cursor: pointer;
      }

      .closeChangeButton {
        margin-right: 15px;
      }
    }
  }
}

.changeCommentTextareaWrapper {
  padding: 0;
  margin: 0;
}
