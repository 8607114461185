@import '../../../../styles/variables/colors';
@import '../../../../styles/variables/typography';

$width: 300px;
$header-width: $width;
$header-avatar-width: 90px;
$header-info-width: 210px;
$main-avatar-width: 90px;
$main-info-width: 160px;
$main-status-width: 50px;

.UserContentContainer {
  display: grid;
  grid-template-rows: 110px calc(calc(var(--vh, 1vh) * 100) - 354px) 180px;
  gap: 2px;
  height: 100%;
  background: var(--white);
  border: none;
  border-right: 3px solid var(--border-color);
  .header {
    display: grid;
    grid-template-columns: $header-avatar-width $header-info-width;
    .avatar {
      width: $header-avatar-width;
      justify-self: end;
      align-self: center;
      display: flex;
      justify-content: center;
    }
    .info {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      width: $header-info-width;
      padding-right: 20px;

      .role,
      .rating,
      .title {
        width: calc(#{$header-info-width} - 10px);
        margin: 0;
        font-size: $font-size-2-desktop;
        line-height: $line-height-2-desktop;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        span {
          color: var(--primary-color);
        }
        svg {
          path {
            fill: #171719;
          }
        }
      }
      .title {
        font-weight: 500;
      }
      .description {
        font-size: $font-size-desktop;
        line-height: 24px;
        font-weight: normal;
        color: $primary;
      }
    }
  }

  .main,
  .footer {
    .list {
      display: grid;
      grid-template-rows: repeat(3, 1fr);
      .item {
        list-style-type: none;
        .link {
          display: grid;
          grid-template-columns: 50px auto;
          .linkIcon {
            justify-self: end;
            align-self: center;
          }
          .linkTitle {
            align-self: center;
            margin-left: 20px;
            color: $black;
            line-height: 35px;
          }
        }
      }
    }
  }
  .footer {
    min-height: 180px;
    display: flex;
    align-items: center;
    border-top: 3px solid var(--border-color);
  }
}
