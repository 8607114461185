.imageWrapper {
  display: flex;
  justify-content: center;
  width: 30%;
  height: auto;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC');
  border-radius: 16px;
  overflow: hidden;

  .image {
    width: 100%;
    height: auto;
    border-radius: 16px;
    cursor: pointer;
  }
}
