@import 'styles/variables/colors';

.UserAgreement {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .reminder {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .icon {
    display: flex;
    flex-shrink: 0;
    position: relative;
    justify-content: center;
    align-items: center;
    $border: 2px;
    padding: 8px;
    background: #ffffff;
    border-radius: 50%;
    background-clip: padding-box;
    border: solid $border transparent;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -$border;
      border-radius: inherit;
      background: linear-gradient(158.3deg, #e33aff 0%, #0238f7 99.76%);
    }
  }

  .button {
    display: inline;
    border: none;
    background: transparent;
    color: $primary;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-transform: none;

    &:hover {
      transform: scale(1.05);
    }
  }
}
