.wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}

.cards {
  display: flex;
  gap: 1rem;
  overflow-x: scroll;
}

.title {
  margin: 0;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  line-height: 47px;
  text-align: center;
  text-transform: uppercase;
  color: #000000;

  @media only screen and (max-width: 1280px) {
    font-size: 40px;
    line-height: 45px;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 25px;
    line-height: 27px;
  }
}
