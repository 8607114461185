@import '../../../styles/variables/grid';
@import '../../../styles/variables/colors';
@import '../../../styles/variables/common';
@import '../../../styles/variables/indention';

.EditUserAvatar {
  display: grid;
  grid-template-areas: 'image' 'description' 'load';
  grid-gap: $space-mobile;

  @media #{$breakpoint-sm-up} {
    grid-template-columns: auto 1fr;
    grid-template-areas: 'image description' 'load load';
  }

  @media #{$breakpoint-lg-up} {
    grid-gap: $space-desktop;
  }

  .imageContainer {
    grid-area: image;
    display: grid;
    justify-content: center;

    .profileImage {
      width: 190px;
      height: 190px;
      background-color: $white;
      border-radius: $border-radius;
      border: 2px dashed #171719;
      align-items: center;
      justify-content: center;

      overflow: hidden;
    }
  }

  .imageDescription {
    grid-area: description;
  }

  .loadImage {
    grid-area: load;

    .confirm {
      display: grid;

      @media #{$breakpoint-sm-only-max} {
        grid-template-rows: 1fr 1fr;
        grid-row-gap: $grid-gap;
      }

      @media #{$breakpoint-md-up} {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: $grid-gap;
      }
    }
  }
}
