@import '/src/styles/variables/colors';

.wrapper {
  max-width: 640px;
  width: 100%;
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;

  @media only screen and (max-width: 740px) {
  }
}

.cropper {
  width: 640px;
  height: 360px;
  overflow: hidden;
  border-radius: 16px;
  margin: 0 auto;

  @media only screen and (max-width: 740px) {
    width: 320px;
    height: 180px;
  }

  @media only screen and (max-width: 480px) {
    width: 192px;
    height: 108px;
  }
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;

  .button {
    margin: 0;
  }

  .secondary {
    border-color: transparent;

    &:hover {
      border-color: $primary;
    }
  }

  @media only screen and (max-width: 740px) {
    justify-content: flex-end;
  }
}
