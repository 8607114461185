@import '/src/styles/variables/colors';
@import '../../styles/variables/typography';

.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.title {
  margin: 0;
}

.toggleContainer {
  display: inline-block;
  height: 34px;
  white-space: nowrap;
}

.label {
  position: relative;
  display: inline-block;

  overflow: hidden;
  border-radius: 20px;
}

.checkbox {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  width: 100%;
  height: 34px;
  margin: 0;

  cursor: pointer;
  opacity: 0;
}

.after,
.before {
  display: inline-block;
  font-size: 15px;
  font-weight: bold;
  line-height: 35px;
  box-sizing: border-box;
}

.before {
  padding: 0 10px 0 38px;
  background-color: $gray-2;
  color: var(--primary-color);
}

.after {
  padding: 0 38px 0 10px;
  background-color: var(--primary-color);
  color: $white;
  display: none;

  text-align: right;
}

.switch {
  position: absolute;
  top: 0;
  left: 0;

  width: 30px;
  height: 30px;
  border-radius: 20px;
  margin: 2px;

  background: $white;
}

.checkbox:checked {
  ~ .before {
    display: none;
  }

  ~ .after {
    display: block;
  }

  ~ .switch {
    left: auto;
    right: 0;
  }
}
