@import 'styles/variables/colors';

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;

  .wallpaper {
    display: flex;
    width: 100%;
    height: auto;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .avatarContainer {
    position: absolute;
    width: fit-content;
    display: flex;
    top: -85px;
    left: 20px;
    align-items: flex-start;
    justify-content: space-between;
  }

  .cardContainer {
    display: flex;
    flex-direction: column;
    padding: 20px;
    flex-shrink: 0;
    gap: 20px;
    position: relative;

    .subContainer {
      display: flex;
      width: 100%;
      flex-direction: column;
      flex-shrink: 0;
      gap: 1rem;

      &:nth-last-child(1) {
        border-bottom: 2px solid var(--border-color);
        padding-bottom: 20px;
      }

      .content {
        margin: 0;
      }
    }
  }
}

.containerWithMargin {
  margin-top: 75px;

  & > h1 {
    margin: 0;
  }
}

.contacts {
  display: flex;

  h5 {
    width: 30%;
  }

  .members {
    display: flex;
    width: 70%;
    gap: 20px;

    p {
      width: fit-content;
    }
  }

  a,
  p {
    width: 70%;
    color: $primary;
  }

  span {
    color: $black;
  }
}

.verificationAllianceInfo {
  display: inline;
  position: relative;
}

.editButtons {
  display: flex;
  gap: 10px;
}

.buttons {
  display: flex;
  align-items: center;
  padding: 0;
  padding-bottom: 35px;
  margin-left: auto;
  gap: 10px;
  z-index: 0;

  .commonButton {
    margin: 0;
  }

  & > div,
  a {
    display: flex;
    cursor: pointer;

    &:hover {
      transform: scale(1.1);

      path {
        stroke: $primary;
      }
    }
  }
}

.edit {
  gap: 0 !important;
}
