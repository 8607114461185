@import 'src/styles/variables/common';

.SuggestionDesktop {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > div {
    margin-bottom: 1rem;
  }

  .titleAvatarWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    .quantity {
      color: var(--primary-color);
    }
  }

  .avatar {
    height: 60px;
    margin-right: 15px;
  }

  .goalDescription {
    text-transform: lowercase;
  }

  .wrapperDesctiptionDoug {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }

  .doughnut {
    display: flex;
    width: 100%;
    margin-top: 10px;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    align-content: center;
    justify-content: center;

    @media screen and (max-width: 1024px) {
      align-items: flex-start;
    }

    .buttonMore {
      background-color: transparent;
    }

    .changeButton {
      display: flex;

      button {
        margin-right: 10px;
        color: var(--primary-color);
        border: none;
        padding: 0;
        background: transparent;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
