@import 'styles/variables/colors';

.wrapper {
  display: flex;
  background-color: $black;
  width: 100%;
  padding: 3rem 1.5rem;
}

.content {
  max-width: 1920px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.title {
  margin: 0;
  height: 80px;
  padding-top: 10px;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 500;
  font-size: 80px;
  line-height: 47px;
  text-transform: uppercase;
  background: linear-gradient(158.3deg, #e33aff 0%, #0238f7 99.76%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  @media only screen and (max-width: 1280px) {
    font-size: 70px;
    line-height: 45px;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 40px;
    line-height: 24px;
  }
}

.text {
  max-width: 108rem;
  width: 100%;

  p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 55px;
    text-transform: uppercase;
    color: #ffffff;

    @media only screen and (max-width: 1280px) {
      font-size: 40px;
      line-height: 45px;
    }

    @media only screen and (max-width: 1024px) {
      font-size: 25px;
      line-height: 27px;
    }
  }
}

.image {
  display: flex;
  margin: 0 auto;
  width: 100%;
}
