@import '../../styles/variables/colors';
@import '../../styles/variables/common';
@import '../../styles/variables/grid';

.RulesService {
  display: grid;
  scroll-behavior: smooth;
  grid-template-rows: auto auto;
  grid-template-columns: 100%;
  margin-top: 10px;

  @media #{$breakpoint-md-up} {
    grid-template-columns: auto 1fr;
  }
  & > aside {
    h3 {
      @media screen and (max-width: 764px) {
        padding: 0;
      }
    }
  }

  h2 {
    text-align: left;
    margin-top: 0;
    padding-top: calc(var(--header-height) + 10px);
    margin-top: calc(-1 * (var(--header-height) + 10px));
  }

  h3 {
    margin-bottom: 1rem;
  }

  p {
    margin: 1rem 0 0 0;
  }

  .list {
    padding-left: 20px;
  }

  main {
    @media screen and (max-width: 764px) {
      padding: 0;
    }
    margin-bottom: 40px;
    .card {
      background-color: $gray-1;
      border-radius: $border-radius;
      margin-bottom: 2rem;

      padding: 16px 27px;

      @media #{$breakpoint-sm-only-max} {
        padding: 1rem;
        padding-left: 27px;
      }

      p {
        margin: 0;
      }

      hr {
        border-top: 2px solid $primary;
        display: inline-block;
        width: 10rem;
      }
    }

    .about {
      margin: 0;
    }

    .aboutDescription {
      opacity: 0.3;
      padding-left: 36px;
    }

    .deletingProfile {
      display: flex;
      align-items: center;
      .icon {
        width: auto;
        padding: 0.7rem 1rem;
        margin-right: 1rem;
      }
      button {
        width: 35rem;
      }
    }
  }
}
