.legend {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.dropdownList {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  & * {
    margin-bottom: 6px;
  }
  & *:last-child {
    margin-bottom: 0;
  }
}

.dropdownTitle {
  font-size: 16px;
  padding-left: 15px;
}
