.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: white;
  position: relative;

  .wrapperSpinner {
    position: absolute;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: #80808017;
  }

  .ThreadMessage {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .threadHeader {
    border-bottom: 3px solid rgba(130, 142, 204, 0.15);
    padding: 17.5px 8px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    gap: 10px;
    height: 62px;

    button {
      display: none;
      margin: 0;

      @media screen and (max-width: 764px) {
        display: flex;
      }
    }

    p {
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  .threadContent {
    flex-grow: 1;
    overflow: auto;
  }
}
