.section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
  background: var(--primary-bg-trans-15);
  padding: 30px 0;
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);
  color: black;
  .noTextArea {
    max-width: 1080px;
    padding: 0 20px;
    width: 100%;
    margin: 20px 0;
  }
  .textareaCreateWrapper {
    width: 100%;
    max-width: 1080px;
  }
  & > h3 {
    width: 100%;
    max-width: 1080px;
    padding: 0 20px;
  }

  .commentsGroup {
    display: flex;
    flex-direction: column;
    max-width: 1080px;
    padding: 0 20px;

    width: 100%;
    .commentWrapper {
      margin-bottom: 5px;
    }
  }
}

.isNotDetailsPageSection {
  background: transparent;
  padding: 0;
  width: 100%;
  margin-left: 0;
  transform: none;
  .commentsGroup {
    padding: 0;
  }
  .textareaCreateWrapper {
    max-width: none;
    & > div {
      padding: 0;
    }
  }
  & > h3 {
    padding: 0;
  }
}
