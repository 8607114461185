@import 'shared/styles/variables/grid';
@import 'shared/styles/variables/common';
@import 'shared/styles/variables/colors';

.KanbanCard {
  outline: none;
  width: 325px;

  @media screen and (max-width: 1024px) {
    width: 285px;
  }

  @media screen and (max-width: 764px) {
    width: 245px;
  }

  .Card {
    background: $gray-1;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    overflow: hidden;
    border-radius: 15px;
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;

    .CardMedia {
      display: flex;

      img {
        max-height: 200px;
        height: auto;
        width: 100%;
        object-fit: cover;
      }
    }

    .CardContent {
      padding: 10px 16px 0;

      .CardTitle {
        display: flex;
        justify-content: space-between;

        > span {
          font-size: 15px;
        }

        .wrapperIconSuggestion {
          background: linear-gradient(158.3deg, #c03aff 0%, #0238f7 99.76%);
          padding: 5px;
          border: none;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          min-width: 30px;
          min-height: 30px;
        }
      }

      .CardContentContainer {
        margin-top: 20px;
        justify-content: space-between;
        gap: 10px;
      }

      .IconWrapper {
        display: flex;
        cursor: pointer;
      }
    }

    .UserLogo {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
    }
  }
}

.FlexCenter {
  display: flex;
  align-items: center;
}

.controls {
  display: flex;
  gap: 10px;
  margin-left: auto;
}

.UsersContainer {
  display: flex;
  flex-direction: row-reverse;

  .UsersLogo {
    display: block;

    align-items: center;
    border: 3px solid $gray-1;
    border-radius: 50%;
    line-height: 18px;
  }
}

.asignBtn {
  margin: 0;
  cursor: pointer;
}

.lightIconWrapper {
  display: flex;
  align-items: center;

  svg {
    width: 20px;
    height: 24px;
  }
}

.coloredlightIcon {
  svg {
    fill: $primary;

    path {
      stroke: $primary;
    }
  }
}
