.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-right: 10px;

  .content {
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    .selectWrapper {
      margin-bottom: 8px;
      > div > div:last-of-type {
        width: inherit;
        margin-top: -80px;
        padding-top: 20px;
        border-radius: 15px;
      }
    }
  }
}

.inputWrapper {
  width: 100%;

  input {
    border: 1px solid #ccc !important;
  }
}
