@import 'styles/variables/colors';

.wrapper {
  display: flex;
  background: $gradient-4;
  border-radius: 0px 16px 0px 0px;
  height: 77px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
