.wrapper {
  display: flex;
  width: 100%;
  padding: 0 1.5rem;
  padding-top: 0;
}

.content {
  max-width: 1920px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 2rem;
}

.title {
  max-width: 53rem;
  width: 100%;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 500;
  font-size: 75.5847px;
  line-height: 76px;
  text-align: right;
  text-transform: uppercase;
  background: linear-gradient(158.3deg, #e33aff 0%, #0238f7 99.76%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  @media only screen and (max-width: 1280px) {
    font-size: 2.5rem;
    line-height: 3rem;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

.image {
  height: auto;
  max-width: 1080px;
  width: 100%;
}

.button {
  padding: 0 5px;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(158.3deg, #e33aff 0%, #0238f7 99.76%);
  border-radius: 16px 16px 0px 16px;

  @media only screen and (max-width: 1280px) {
    max-width: 100%;
    width: 15rem;
    height: 3rem;
  }

  @media only screen and (max-width: 1024px) {
    width: 10rem;
    height: 2.5rem;
  }

  p {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 22px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;

    @media only screen and (max-width: 1280px) {
      font-size: 0.7rem;
      line-height: 1rem;
    }

    @media only screen and (max-width: 1024px) {
      font-size: 0.5rem;
    }
  }
}

.text {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;
}
