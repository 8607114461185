.contactUs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 20px;
  span,
  a {
    font-size: 18px;
  }
  .socialLinks {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;

    @media screen and (max-width: 500px) {
      flex-direction: column;
    }
    div {
      margin: 0;
      margin-left: 15px;
      @media screen and (max-width: 500px) {
        margin-left: 0;
      }
    }
  }
}
