.ForgetPassword {
  form {
    margin: 20px 0;
  }

  .actions {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    gap: 10px;
  }
}
