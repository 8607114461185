.container {
  margin: 0 auto;
  //add mixin for paddings
  padding: 0 20px;
  width: 100%;
  max-width: 1280px;
  margin-top: 40px;

  @media screen and (max-width: 764px) {
    padding: 10px;
  }
}

.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  .organizationLink {
    height: 45px;
  }
}

.content {
  width: 100%;

  .columns {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .column {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  max-width: 450px;
}

.scroll {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
