.profileWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  height: calc(100vh - var(--header-height));
  width: 100%;
  position: relative;

  p {
    margin: 0;
  }
}
