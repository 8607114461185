@import 'styles/variables/colors';

.wrapper {
  display: flex;
  flex-direction: row-reverse;
  border: 2px solid var(--border-color);
  height: 180px;
  overflow: hidden;

  a {
    &:hover {
      opacity: 1;
      transform: scale(1.01);
    }
  }
}

.mobileWrapper {
  height: 500px;
  border-right: none;
  border-left: none;
}

.image {
  display: flex;
  position: relative;
  max-height: 330px;
  width: 50%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  aspect-ratio: 2 / 1;

  .link {
    top: 15px;
    right: 15px;
    position: absolute;
    display: flex;
    opacity: 1;

    .organization {
      &:hover {
        border: 2px solid $primary;
        opacity: 1;
      }
    }
  }

  .extra {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin: 0;

    .eventWrapper {
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      color: #ffffff;

      .eventText {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        color: #ffffff;
      }
    }

    &::before {
      z-index: 0;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: black;
      opacity: 0.6;
    }
  }

  img {
    width: 100%;
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 10px 20px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }

  .author {
    width: 100%;
    display: flex;
    gap: 10px;

    .title {
      display: flex;
      flex-direction: column;
      max-width: 100%;
    }

    span {
      color: $primary;
    }
  }
}

.contentLink {
  display: flex;
  color: $black !important;
  overflow: hidden;
  word-wrap: break-word;
  width: 100%;

  h4,
  div {
    max-width: 100%;
  }
}

.titleLink {
  max-height: 80px;
}
