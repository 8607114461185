:global(.rbc-selected) {
  background-color: #06baab !important;
}

:global(.rbc-event) {
  background-color: #06baab !important;
  border-radius: 0px 16px 16px 16px !important;
}

:global(.rbc-event-content) {
  color: #ffffff !important;
}
:global(.rbc-event-label) {
  color: #ffffff !important;
  flex: auto !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: auto !important;
}

:global(.rbc-toolbar) {
  flex-direction: row-reverse;
}

:global(.rbc-btn-group) {
  display: flex;
  gap: 2px;
  button {
    border: none;
    border-radius: 0 !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    border-bottom: 3px solid #e6e8f2;
  }
}

:global(.rbc-active) {
  border-bottom: 3px solid #5002f7 !important;
  color: #5002f7 !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

:global(.rbc-header) {
  text-transform: uppercase;
}

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: calc(100vh - var(--header-height));
  width: 100%;
  position: relative;
}
