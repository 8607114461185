@import 'shared/styles/variables/colors';
@import 'shared/styles/variables/common';

.ql-toolbar.ql-snow {
  border: none !important;
  font: inherit;
  letter-spacing: inherit;
}

.ql-snow {
  border: none !important;
  font: inherit;
  letter-spacing: inherit;
}

.ql-container .ql-blank::before {
  font: inherit;
  letter-spacing: inherit;
  color: rgba(0, 0, 0, 0.6);
}

.ql-container {
  .ql-editor {
    a {
      word-break: break-all;
      white-space: unset !important;
    }
  }
}

.ql-tooltip,
.ql-picker-options {
  background-color: $gray-2 !important;
  color: $gray-4 !important;
}

.ql-picker-options {
  border-radius: 0 0 16px 16px;
}

.ql-picker-item {
  transition: all $animation-duration ease;
  color: $gray-4 !important;

  &:hover {
    color: $primary !important;
  }
}

.ql-tooltip-arrow {
  border-bottom: 6px solid $gray-2 !important;
}

.ql-stroke {
  transition: all $animation-duration ease;
  stroke: $gray-4 !important;
}

.ql-fill {
  transition: all $animation-duration ease;
  fill: $gray-4 !important;
}

.ql-picker-label {
  color: $gray-4 !important;

  &:active {
    color: $black !important;
  }
}

.ql-formats {
  button {
    &:hover {
      .ql-stroke {
        stroke: $primary !important;
      }

      .ql-fill {
        fill: $primary !important;
      }
    }
  }
}

.ql-active {
  .ql-stroke {
    stroke: $black !important;
  }

  .ql-fill {
    fill: $black !important;
  }
}

.ql-blank {
  font-size: 15px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
}
