.noPost {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  .noPostImage {
    width: 400px;
    height: 400px;
    object-fit: cover;
    margin-bottom: 35px;
  }
  .noPostContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      color: #222526;
    }
    .subtitle {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      display: flex;
      align-items: center;
      text-align: center;

      color: #7e7e80;
    }
  }
}
