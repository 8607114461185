@import 'styles/variables/colors';

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  h5 {
    margin: 10px 0;
  }
}

.openBtn {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;

  &:hover {
    h5,
    path {
      transform: scale(1.05);
    }
  }
}
