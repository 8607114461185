.wrapper {
  position: relative;
  color: #000;
  .deleteIcon {
    position: absolute;
    top: -8px;
    right: -8px;
    cursor: pointer;
    z-index: 10000;
    opacity: 0;
    transition: all 0.1s ease;
  }
  &:hover {
    .deleteIcon {
      opacity: 1;
    }
  }

  .imgWrapper {
    cursor: pointer;
    margin-left: 8px;
  }
}
