@import '../../styles/variables/grid';

html,
body {
  margin: 0;
  width: 100%;
}

body {
  position: relative;
}

//.page {
//  display: flex;
//  flex-direction: column;
//  align-items: stretch;
//  position: relative;
//  padding: 1.5rem 0;
//  min-height: 100%;
//  box-sizing: border-box;
//  overflow: hidden;
//
//  @media #{$breakpoint-sm-up} {
//    padding: 3.3rem 0;
//  }
//
//  @media #{$breakpoint-md-up} {
//    padding: 3.3rem 0;
//  }
//
//  @media #{$breakpoint-xl-up} {
//    padding: 5.5rem 0;
//  }
//}
//
//header, section, footer {
//  flex: 0 0 auto;
//  margin: 0 1rem;
//  box-sizing: border-box;
//
//  @media #{$breakpoint-sm-up} {
//    margin: 0 2.7rem;
//  }
//
//  @media #{$breakpoint-md-up} {
//    margin: 0 2.7rem;
//  }
//
//  @media #{$breakpoint-xl-up} {
//    margin: 0 4.4rem;
//  }
//}

section {
  flex-grow: 1;
}

img {
  max-width: 100%;
  height: auto;
}
