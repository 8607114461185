@import 'styles/variables/colors';

.wrapper {
  display: flex;
  align-items: center;
  gap: 0.2rem;
}

.bottomSide {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.wrapperFooter {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.content {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: $black;
}

.active {
  cursor: pointer;

  &:hover {
    path {
      stroke: $primary;
      transform: scale(1.05);
    }
  }
}

.leftSide,
.rightSide,
.bottomSide {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  flex-wrap: wrap;
  gap: 1rem;
}
