@import 'styles/variables/colors';

.wrapper {
  display: flex;
  width: 150px;
  align-items: center;
  padding: 25px 15px;
  background-color: $gray-2;
  border-radius: 0px 16px 16px 16px;
  gap: 20px;
  opacity: 0.5;
  max-height: 75px;

  @media only screen and (max-width: 600px) {
    width: calc(50% - 10px);
  }

  @media only screen and (max-width: 480px) {
    width: 100%;
  }

  p {
    margin-bottom: 0;
  }
}

.active {
  opacity: 1;
}
