@import 'styles/variables/grid';
@import 'styles/variables/common';
@import 'styles/variables/colors';

.StepCredentials {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  @media screen and (max-width: 1280px) {
    flex-direction: column;
  }

  .RegistrationCheckbox {
    label {
      height: auto;

      span:after {
        transform: translateY(-30%);
        top: 50%;
      }
    }

    div {
      flex: 1;
      word-break: break-word;
    }
  }

  ul {
    li {
      &:first-child {
        @media #{$breakpoint-md-up} {
          border-bottom-left-radius: $border-radius;
        }
      }

      &:last-child {
        @media #{$breakpoint-md-up} {
          border-bottom-right-radius: $border-radius;
        }
      }
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    padding-top: 1.5rem;
    gap: 10px;
  }
}

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  flex-wrap: wrap;

  .button {
    min-width: 220px;
    margin: 0;
  }
}

.withDivider {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;

  &:before,
  &:after {
    content: '';
    flex: 1 1;
    border-bottom: 1px solid;
    margin: auto;
  }

  &:before {
    margin-right: 10px;
  }

  &:after {
    margin-left: 10px;
  }
}

.description {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 470px;
  overflow-y: scroll;

  @media screen and (max-width: 1280px) {
    flex-direction: column;
    max-width: fit-content;
    width: 80%;
  }

  ul {
    margin-left: 20px;
  }

  p,
  li {
    margin-bottom: 1rem;
  }

  .primary {
    color: $primary;
  }
}

.link_btn {
  display: flex;
  width: fit-content;
  white-space: nowrap;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.content {
  width: 100%;
  max-width: 470px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
