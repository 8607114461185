@import 'styles/variables/colors';
@import 'styles/variables/common';
@import 'styles/variables/grid';

.wrapper {
  display: flex;
  flex-direction: column;
  width: 415px;
  margin: 0 -1.5rem;
  gap: 10px;
  max-height: 70vh;

  .title {
    padding: 0 1.5rem;
  }

  .list {
    display: flex;
    flex-direction: column;
    max-height: 600px;
    overflow-y: auto;
  }

  .button {
    margin: 0 1.5rem 0 auto;
  }

  .noContent {
    display: flex;
    padding: 1.5rem;
    text-align: center;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 75px;
  }
}
