@import 'styles/variables/colors';

.wrapper {
  display: flex;
  width: 100%;
  max-width: 500px;
  flex-direction: column;
  gap: 20px;

  .props {
    display: flex;
    gap: 20px;
    width: 100%;
    overflow: hidden;

    .propsList {
      display: flex;
      flex-direction: column;
      width: max-content;
      justify-content: center;

      .propTitle {
        margin: 0;
      }

      .propDescription {
        color: $primary;
      }

      .accepted {
        color: $green-text;
      }

      .rejected {
        color: $red;
      }
    }
  }

  .button {
    margin: 0;
    margin-left: auto;
  }
}
