@import 'styles/variables/colors';

.wrapper {
  display: flex;
  background-color: $white;
  max-width: 300px;
  width: 100%;
  padding: 1rem 1.5rem;
  gap: 1.5rem;

  @media only screen and (max-width: 1280px) {
    max-width: 200px;
    width: 100%;
    padding: 1rem 1.5rem;
    gap: 1rem;
  }

  @media only screen and (max-width: 1024px) {
    max-width: 150px;
    width: 100%;
    padding: 0.5rem 0.7rem;
    gap: 0.5rem;
  }
}

.step {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  line-height: 47px;
  text-transform: uppercase;
  background: linear-gradient(158.3deg, #e33aff 0%, #0238f7 99.76%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  @media only screen and (max-width: 1280px) {
    font-size: 40px;
    line-height: 45px;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 25px;
    line-height: 27px;
  }
}

.text {
  max-width: 12rem;
  width: 100%;

  h2 {
    margin: 0;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 30px;
    text-transform: uppercase;
    color: #000000;

    @media only screen and (max-width: 1280px) {
      font-size: 15px;
      line-height: 20px;
    }

    @media only screen and (max-width: 1024px) {
      font-size: 12px;
      line-height: 15px;
    }
  }
}
