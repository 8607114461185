@import 'styles/variables/colors';

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;

  .wallpaper {
    display: flex;
    width: 100%;
    height: auto;
    overflow: hidden;
    border-bottom: 2px solid var(--border-color);

    img {
      width: 100%;
      height: 100%;
    }
  }

  .avatarContainer {
    position: absolute;
    width: 100%;
    display: flex;
    top: -85px;
    left: 0;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 20px;

    .buttons {
      display: flex;
      padding: 10px 0;
      margin-top: 85px;
      gap: 10px;

      & > div,
      a {
        display: flex;
        cursor: pointer;

        &:hover {
          transform: scale(1.1);

          path {
            stroke: $primary;
          }
        }
      }
    }
  }
  .wrapperCard {
    padding: 20px;
    padding-top: 0;
    .cardContainer {
      display: flex;
      flex-direction: column;
      padding: 20px;
      gap: 20px;
      flex-shrink: 0;
      position: relative;
      padding-bottom: 30px;
      border: 2px solid var(--border-color);
      border-top: none;

      .subContainer {
        display: flex;
        width: 100%;
        flex-direction: row;
        flex-shrink: 0;
        gap: 1rem;

        .content {
          margin: 0;
        }
      }
    }
  }
  .mobileWrapper {
    padding: 0;
    .cardContainer {
      border-left: none;
      border-right: none;
    }
  }
}

.containerWithMargin {
  margin-top: 75px;
  & > h1 {
    margin: 0;
  }
}

.bordered {
  flex-direction: column !important;
  .contacts {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    gap: 20px;

    .members {
      display: flex;
      gap: 20px;
    }

    a,
    p {
      color: $primary;
    }

    span {
      color: $black;
    }
  }
}

.verificationAllianceInfo {
  display: inline;
  position: relative;
}
