.wrapper {
  max-width: 680px;
  ul {
    margin-left: 20px;
  }

  a {
    color: var(--link-color);
  }

  img {
    width: 100%;
  }
}
