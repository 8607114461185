@import 'styles/variables/colors';

.socialIcon {
  display: block;
  position: relative;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  user-select: none;
  cursor: pointer;

  .delete {
    display: none;
    position: absolute;
    top: 0;
    right: -10px;

    &:hover {
      line {
        stroke: $primary;
      }
    }
  }

  &:hover {
    .delete {
      display: flex;
    }
  }

  img {
    object-fit: cover;
  }
}

.active {
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
}
