@import 'styles/variables/colors';

.wrapper {
  display: flex;
  width: 100%;
  row-gap: 10px;
  column-gap: 20px;
  align-items: center;

  &:hover {
    opacity: 1;

    .info {
      transform: scale(1.05);
    }

    .image {
      border: 3px solid $primary;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    max-height: 150px;
    overflow: scroll;

    h4 {
      text-transform: capitalize;
    }
  }

  .image {
    display: flex;
    flex-shrink: 0;
  }
}

.content {
  display: flex;
  gap: 10px;
  flex-direction: column;

  .button {
    margin: 0;
  }
}
