@import '../../../styles/variables/grid';

.wrapper {
  min-width: 480px;
  max-width: 600px;
  word-break: break-all;

  @media #{$breakpoint-xs-only} {
    min-width: auto;
  }

  .title {
    margin-top: 10px;
    text-align: left;
    font-weight: 500;
    font-size: 20px;

    @media screen and (max-width: 764px) {
      margin-top: 0;
    }
  }

  p {
    margin-top: 0;
  }

  .button {
    margin: 10px auto;
  }

  .date {
    display: flex;
    justify-content: flex-end;
    color: grey;
    font-size: 12px;
  }
}
