.wrapper {
  li {
    display: flex;
    padding: 8px;
    cursor: pointer;

    > svg {
      margin-right: 16px;
    }

    > p {
      font-size: 15px;
      line-height: 30px;
    }
  }
}

.disabled {
  opacity: 0.5;
}
