@import '../../styles/variables/typography';
@import '../../styles/variables/grid';

.LaborUnionInfo {
  position: relative;
  overflow: auto;
  display: flex;
  align-items: center;

  p {
    margin-bottom: 0;
    font-size: $l_body_1920-size;
  }
  div {
    min-height: 75px;
    height: 75px;
    min-width: 75px;
    width: 75px;

    border-radius: 50%;
    float: left;
    margin-right: 1rem;

    img {
      width: 100%;
      object-fit: contain;
    }
  }
}
