@import 'shared/styles/variables/colors';
@import 'shared/styles/variables/common';

.wrapper {
  display: flex;
  height: calc(100vh - var(--header-height));
  width: 100%;
  position: relative;
}

.slide {
  display: flex;
  max-height: 100%;
  width: 100%;
  overflow: auto;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 880px;
  padding: 0 20px;
  position: relative;
  z-index: 2;
}

.settings {
  position: absolute;
  z-index: 3;
  display: flex;
  align-items: flex-start;
  background-color: $white;
  right: -100%;
  transition: all $animation-duration ease;
  height: 100%;

  &.open {
    right: 0;
  }
}

.buttons {
  padding-bottom: 20px;
  padding-left: 15px;
}

.textarea {
  display: flex;

  flex-shrink: 0;
  font-family: inherit;
  font-size: 35px;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;

  outline: 0px none transparent;
  width: 100%;
  padding: 12px 15px;
  border: none;
  overflow: auto;
  resize: none;
  background: transparent;
}

.close {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  padding: 20px;
  cursor: pointer;

  &:hover {
    path {
      transition: all 0.4s ease;
      stroke: $primary;
    }
  }
}
