@import '../../styles/variables/colors';

.infinityScroll {
  overflow: hidden !important;

  @media screen and (max-width: 764px) {
    padding: 0;
  }
}

.dontFindData {
  padding-top: 20px;
  padding-bottom: 20px;
  margin: 0 auto;
  text-align: center;
  font-size: 20px;
}

.spinnerWrapper {
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
}
