.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 20px 0;
}

.mobileWrapper {
  padding: 0;

  .title {
    padding-top: 20px;
  }
}

.title {
  display: flex;
  align-items: center;
  gap: 10px;

  h2 {
    margin: 0;
  }

  .controls {
    display: flex;
  }

  .tab {
    width: 350px;
    margin-left: auto;
  }
}

.mobile {
  flex-wrap: wrap;

  .tab {
    width: 100%;
    margin: 0;
  }

  .controls {
    margin-left: auto;
  }
}

.button {
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  align-items: center;
  justify-content: center;

  path {
    stroke-width: 3;
  }

  &:hover {
    transform: scale(1.1);
  }

  &:disabled {
    cursor: auto;
    opacity: 0.5;

    &:hover {
      transform: scale(1);
    }
  }
}
