.costs {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;
  overflow-x: auto;
  overflow-y: auto;
  padding: 40px;

  @media screen and (max-width: 800px) {
    padding: 10px;
  }

  h3 {
    margin-bottom: 20px;
  }

  .costsWrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;

    @media screen and (max-width: 1000px) {
      flex-direction: column;
    }

    .doughnut {
      width: 50%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      @media screen and (max-width: 1000px) {
        width: 100%;
        align-items: center;
      }

      @media screen and (max-width: 800px) {
        margin-bottom: 20px;
      }

      .doughnutWrapper {
        width: 65%;

        @media screen and (max-width: 800px) {
          width: 100%;
        }
      }
    }

    .costsList {
      width: 50%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      @media screen and (max-width: 1000px) {
        width: 100%;
        align-items: center;
      }

      .costsListWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-right: 20px;

        @media screen and (max-width: 1000px) {
          padding: 0;
        }

        .costWrapper {
          display: flex;
          margin-top: 5px;
          align-items: center;
          justify-content: space-between;
          gap: 10px;

          .costAmmount {
            min-width: fit-content;
            height: fit-content;
          }

          span {
            font-size: 16px;
            margin-right: 10px;
          }
        }
      }
    }
  }

  .summ {
    display: flex;
    margin-top: 20px;

    h4 {
      margin-right: 10px;
    }
  }
}

.mobile {
  padding: 10px;

  .costsWrapper {
    flex-direction: column;

    .doughnut {
      width: 100%;
      align-items: center;
      margin-bottom: 20px;

      .doughnutWrapper {
        width: 100%;
      }
    }

    .costsList {
      width: 100%;
      align-items: center;

      .costsListWrapper {
        padding: 0;
      }
    }
  }
}
