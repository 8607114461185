@import 'styles/variables/colors';

.wrapper {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;

  @media screen and (max-width: 1024px) {
    transform: rotate(180deg);
  }

  .button {
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    align-items: center;
    justify-content: center;
    height: 64px;

    &:hover {
      transform: scale(1.05);
    }
  }

  svg {
    fill: white;
  }

  .hide {
    display: flex;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
}
