.selectList {
  position: absolute;
  background-color: #ffffff;
  top: 85px;
  right: 0;
  z-index: 20;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  border-bottom: 2px #e6e8f2 solid;
  border-left: 2px #e6e8f2 solid;
  border-top: 2px #e6e8f2 solid;
}

.selectItem {
  cursor: pointer;
}

.active {
  color: #5002f7;
}
