.wrapper {
  display: flex;
  width: 100%;
  padding: 3rem 1.5rem;
}

.content {
  max-width: 1920px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.title {
  margin: 0;
  font-style: normal;
  font-family: 'Jost';
  font-weight: 500;
  font-size: 50px;
  line-height: 47px;
  text-transform: uppercase;
  background: linear-gradient(158.3deg, #e33aff 0%, #0238f7 99.76%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  @media only screen and (max-width: 1280px) {
    font-size: 40px;
    line-height: 45px;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 25px;
    line-height: 27px;
  }
}

.list {
  li {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 30px;
    text-transform: uppercase;
    color: #171719;

    @media only screen and (max-width: 1280px) {
      font-size: 15px;
      line-height: 20px;
    }

    @media only screen and (max-width: 1024px) {
      font-size: 12px;
      line-height: 15px;
    }
  }
}

.block_header {
  display: flex;
  gap: 7rem;

  @media only screen and (max-width: 1280px) {
    gap: 5rem;
  }

  @media only screen and (max-width: 1024px) {
    gap: 3rem;
  }
}

.plans_list {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  width: 100%;

  @media only screen and (max-width: 1024px) {
    overflow-x: scroll;
  }
}
