@import '/src/styles/variables/indention';
.main {
  display: flex;
  flex-direction: column;
  .wrapper {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    .contentWrapper {
      max-width: 1040px;
      width: 100%;
      margin: 0 auto;
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .content {
      margin-bottom: 30px;
      font-size: 18px;
      p {
        img {
          margin: 0 auto;
          border-radius: 15px;
        }
      }
      ul {
        margin-left: 15px;
      }
      .inviteWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--primary-bg-trans-15);
        width: 100vw;
        margin-left: 50%;
        transform: translateX(-50%);
        padding: 10px 10px;
        margin-bottom: 20px;
        .invite {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          max-width: 1040px;
          @media screen and (max-width: 500px) {
            flex-direction: column;
            justify-content: center;
          }
          svg {
            margin-right: 50px;
            @media screen and (max-width: 500px) {
              margin-right: 0;
              width: 250px;
            }
          }
        }
      }
    }
    .createrWrapper {
      width: 100%;
      margin: 0 auto;
      max-width: 1040px;
    }
    .titleWrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 20px;
      background: var(--primary-bg-trans-15);
      padding: 10px 0;
      width: 100vw;
      margin-left: 50%;
      transform: translateX(-50%);
      .title,
      .sectionTitle {
        max-width: 1080px;
        padding: 0 20px;
        margin: 0;
        @media screen and (max-width: 764px) {
          padding: 0 10px;
        }
      }
      .sectionTitle {
        width: 100%;
        text-align: left;
        text-transform: uppercase;
        color: #828ecc;
        font-size: 20px;
        line-height: 25px;
      }
    }
    .contentBottom {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      @media screen and (max-width: 940px) {
        flex-direction: column;
        align-items: flex-start;
      }
      .buttons {
        display: flex;
        width: 100%;
        flex-direction: column-reverse;
        justify-content: flex-end;
        @media screen and (max-width: 940px) {
          width: 100%;
          justify-content: flex-start;
        }
        .pageButtons {
          display: flex;
          justify-content: space-between;
          :only-child {
            margin-left: auto;
          }
          :nth-child(2) {
            margin-left: 30px;
            @media screen and (max-width: 770px) {
              margin-left: 0;
              margin-top: 20px;
            }
          }
          @media screen and (max-width: 770px) {
            flex-direction: column;
            .pageButton {
              width: 100%;
            }
          }
        }
      }
      .findError {
        all: inherit;
        display: inline;
        cursor: pointer;
        width: auto;
        align-self: flex-end;
        color: var(--primary-color);
      }
    }
    .commentWarningWrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      border: green solid 1px;
      .commentWarningIcon {
        padding: 10px 15px 0 15px;
      }
    }
    .commentWrapper {
      padding-top: 20px;
    }
  }
}
