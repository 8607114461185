@import '../../../styles/variables/colors';
@import '../../../styles/variables/common';
@import '../../../styles/variables/grid';
@import '../../../styles/typography/common';

.Suggestions {
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  border: 2px solid var(--border-color);

  @media screen and (max-width: 764px) {
    border-radius: 0px;
    padding: 10px;
  }

  @media #{$breakpoint-md-up} {
    grid-template-columns: 1.5fr 1fr;
    grid-gap: 0;
  }

  @media #{$breakpoint-lg-up} {
    flex-direction: row;
    justify-content: space-between;

    grid-template-rows: 1fr;
    grid-template-columns: 50% minmax(auto, 640px);
    max-height: 650px;
  }

  .left {
    height: 100%;
  }

  .right {
    position: relative;

    max-height: 100%;
    overflow-y: auto;

    @media #{$breakpoint-sm-only-max} {
      min-height: none;
    }

    @media #{$breakpoint-md-only-max} {
      h3 {
        font-size: $font-size-mobile;
        line-height: $line-height-mobile;
        text-transform: initial;
      }
    }

    .collapsed {
      display: none !important;

      @media #{$breakpoint-md-up} {
        opacity: 0;
        visibility: collapse;
        transition: visibility 250ms, opacity 250ms;
      }
    }

    .suggestionInfo {
      height: 100%;

      @media #{$breakpoint-sm-only-max} {
        display: none;
      }
    }

    .suggest {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
