.wrapper {
  display: flex;
  flex-direction: column;
  max-width: 480px;
  width: 100%;
  gap: 20px;
}

.content {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 15px;
}
