.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.about {
  display: flex;
  gap: 20px;
}

.text {
  display: inline-flex;

  p,
  a {
    font-size: 15px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
  }
}
