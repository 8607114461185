.wrapper {
  padding-top: 30px;
  max-width: 800px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin: 0 auto;
  gap: 30px;

  @media screen and (max-width: 764px) {
    padding: 15px;
  }
}
