.comment {
  display: flex;
  align-items: flex-start;
  margin: 10px 0;

  .imageWrapper {
    margin-top: 6px;
  }

  .commentContent {
    margin-left: 16px;
    width: 100%;

    .commentAuthor {
      font-weight: 500;
      margin-right: 10px;
    }
    .commentDate {
      font-size: 15px;
      line-height: 30px;
      color: #171719;
      opacity: 0.5;
      font-weight: 400;
    }
    .commentText {
      line-height: 30px;
    }
    .readAll {
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-line-clamp: 4;
      display: -webkit-box;
      word-wrap: break-word;
      -webkit-box-orient: vertical;
      box-orient: vertical;
      cursor: pointer;
    }
    .readMoreButton {
      background: transparent;
      border: none;
      display: inline;
      color: #171719;
      opacity: 0.5;
      padding: 0;
      cursor: pointer;
    }
    .reactionsWrapper {
      margin-top: 10px;
      display: flex;
      align-items: center;
      @media screen and (max-width: 900px) {
        flex-wrap: wrap;
      }
      .reaction {
        display: flex;
        align-items: center;
        margin-right: 10px;

        & > button {
          background: transparent;
          border: none;
          height: 24px;
          cursor: pointer;
        }
        .countReaction {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 30px;
          background: rgb(255, 255, 255);
          min-width: 30px;
          padding: 0 10px;
          border-radius: 27px;
        }
      }
      .activeReaction {
        .countReaction {
          background: #6339fb;
          & > span {
            color: rgb(255, 255, 255);
          }
        }
      }
      .deletedComment {
        opacity: 0.7;

        & > button {
          cursor: default;
        }
      }
      .answer {
        background: transparent;
        border: none;
        display: inline;
        color: #4455ea;
        cursor: pointer;
      }
    }
    .wrapperAnswerTextArea {
      width: 100%;
      margin: 10px 0 0 0;
    }
  }
}
