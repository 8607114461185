@import 'styles/variables/colors';

.wrapper {
  display: flex;
  flex-direction: column;
  width: 390px;

  .block {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-bottom: 2px solid var(--border-color);
    padding: 10px 0 20px;

    &:nth-last-child(1) {
      border: none;
    }

    .badges {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      gap: 10px;
    }

    .title {
      margin: 0;
    }
  }
}
