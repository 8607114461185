@import '../../../styles/variables/grid';
@import '../../../styles/variables/common';
@import '../../../styles/variables/colors';
@import '../../../styles/variables/typography';

.wrapper {
  margin-top: 40px;

  @media screen and (max-width: 764px) {
    margin-top: 20px;
  }

  & > h2 {
    margin: 5px 15px;
    font-weight: 500;
    font-size: 28px;
    line-height: 40px;
    /* identical to box height, or 125% */

    text-transform: uppercase;

    /* black */

    color: #171719;

    opacity: 0.9;
  }
}

.wrapperCard {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: var(--primary-bg-trans-15);
  border-radius: 15px;

  position: relative;
  box-shadow: 0px 0px 15px -8px rgba(66, 68, 90, 1);

  @media screen and (max-width: 1200px) {
    flex-direction: column-reverse;
  }

  @media screen and (max-width: 764px) {
    flex-direction: column;
    border-radius: 0;
  }

  .mainInfo {
    width: 50%;
    padding: 45px;

    @media screen and (max-width: 1200px) {
      width: 100%;
    }

    @media screen and (max-width: 764px) {
      padding: 15px;
    }

    .avatarTitleBlock {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      @media screen and (max-width: 764px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      .organisationAvatar {
        position: relative;
        margin-right: 3rem;

        @media screen and (max-width: 764px) {
          margin-right: 0;
        }

        .chatIcon {
          position: absolute;
          left: 60px;
          z-index: 1;
        }

        @media #{$breakpoint-md-only-max} {
          display: grid;
          justify-content: center;
        }

        @media screen and (max-width: 764px) {
          display: none !important;
        }
      }

      .titleBlock {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .sectionTitleName {
          font-size: 25px;
          line-height: 35px;
          text-transform: uppercase;
          color: #000000;
          opacity: 0.9;
          text-align: left;
          margin-bottom: 10px;
          max-width: 550px;
          text-decoration: none;

          .verificationAllianceInfo {
            display: inline;
            position: relative;
          }

          @media screen and (max-width: 764px) {
            font-size: 22px;
          }
        }
      }
    }

    .focusWrapper {
      display: block;
      max-width: 640px;
      margin-bottom: 40px;

      @media screen and (max-width: 764px) {
        margin-bottom: 20px;
        max-height: 300px;
        overflow-y: auto;
      }
    }

    .buttonWrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      align-items: center;
    }

    .buttonContacts {
      margin: 0;
      width: fit-content;

      &:hover {
        box-shadow: none;
      }

      @media screen and (max-width: 764px) {
        max-width: 100%;
        width: 100%;
        align-self: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      & > svg {
        width: 20px;
        height: 20px;

        @media screen and (max-width: 1200px) {
          display: none;
        }

        & > path {
          stroke: black;
        }
      }
    }
  }

  .activeInfoWrapper {
    width: 50%;
    position: relative;
    padding: 45px;

    @media screen and (max-width: 1200px) {
      width: 100%;
      min-height: 300px;
    }

    @media screen and (max-width: 1200px) {
      padding: 20px 35px 35px 35px;
    }

    @media screen and (max-width: 764px) {
      padding: 15px;
    }

    .wrapperBanner {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      visibility: hidden;
      margin: -45px;
      transition: all 0.3s ease-in;

      @media screen and (max-width: 1200px) {
        margin: -20px -35px -35px -35px;
      }

      @media screen and (max-width: 764px) {
        margin: -15px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0 15px 15px 0;

        overflow: hidden;
        object-position: top;

        @media screen and (max-width: 1200px) {
          border-radius: 15px;
        }
      }
    }

    .active {
      opacity: 1;
      visibility: visible;
    }
  }

  .organisationShortInfo {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    @media screen and (max-width: 764px) {
      justify-content: space-between;
    }

    & > div {
      display: flex;
      align-items: center;
    }

    & > div > strong {
      margin-right: 5px;
      font-weight: 500;
      font-size: 16px;
    }

    & > div > span {
      margin-right: 10px;
    }

    .organisationCount {
      padding: 1px 10px;
      border-radius: 8px;
      background: linear-gradient(158.3deg, #c03aff 0%, #0238f7 99.76%);
      color: #fff;
    }

    .organisationUsersCount {
      padding: 0px 10px;
      border-radius: 50%;
      background: linear-gradient(158.3deg, #c03aff 0%, #0238f7 99.76%);
      color: #fff;
    }
  }

  .organisationActiveInfo {
    flex-direction: column;
    align-items: flex-start;

    & div {
      margin-bottom: 20px;
    }

    & div:nth-last-child(1) {
      margin-bottom: 0;
    }
  }
}
