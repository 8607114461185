@import 'styles/variables/colors';

.searchWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: 1315px) {
    flex-direction: column;
  }

  @media screen and (max-width: 1024px) {
    align-items: flex-start;
  }

  @media screen and (max-width: 764px) {
    padding: 0 10px;

    div {
      &:nth-child(3) {
        display: none;
      }
    }
  }

  .searchInput {
    @media screen and (max-width: 764px) {
      width: 100%;
      max-width: none;
    }
  }

  & > div {
    max-width: 500px;
    width: 100%;
    margin-bottom: 0;

    &:nth-child(n + 2) {
      margin-left: 20px;

      @media screen and (max-width: 1315px) {
        margin-left: 0;
      }
    }
  }

  & > button {
    width: 200px;
    margin-left: 20px;

    @media screen and (max-width: 1315px) {
      margin-left: 0;
    }
  }
}

.searchOrganizationTitle {
  margin-bottom: -20px;
  padding: 0 10px;
}

.createBtn {
  position: fixed;
  width: 480px;
  max-width: 90%;
  z-index: 2;
  bottom: 1.5rem;
  margin-bottom: 0;
  width: 480px;
  z-index: 2;
  opacity: 0.8;
  max-width: calc(100vw - 3rem - 3.4rem);

  @media screen and (max-width: 1024px) {
    right: 1rem;
    padding: 0;
  }

  .removeLabel {
    @media screen and (max-width: 320px) {
      display: none;
    }
  }
}

.spinnerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 40px;
}

.removeBtn {
  @media screen and (max-width: 764px) {
    display: none;
  }
}

.mapWrapper {
  position: relative;
  display: flex;
  width: 100%;
  height: calc(100vh - var(--header-height));

  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    overflow: hidden;
  }
}

.mapUnionsBar {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 500px;
  align-items: stretch;
  z-index: 3;
  background-color: #fff;
  border-radius: 0 15px 15px 0;
  transition: all 300ms;

  @media screen and (max-width: 1024px) {
    position: absolute;
    bottom: 0;
    height: 50%;
    width: 100%;
  }
}

.searchSection {
  flex: 0 0 auto;
  display: flex;
}

.scrollWrapper {
  overflow-y: auto;
  width: 100%;
  position: relative;
  flex: 1 1 auto;
}

// .infiniteScroll {
//   width: 100%;
//   height: 600px;
//   position: relative;
// }

.mapboxWrapper {
  width: 100%;
  height: 100%;

  @media screen and (max-width: 1024px) {
    position: absolute;
    top: 0;
    padding: 0;
  }
}

.switchButton {
  display: none;
  position: absolute;
  z-index: 5;
  top: -40px;
  right: 0px;
  cursor: pointer;

  svg {
    fill: $white;
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    // transform: translate(0, -100px);
  }
}

.close {
  @media screen and (max-width: 1024px) {
    transform: translate(0, 100%);
  }
}

.searchInput {
  & :global(.description) {
    display: none;
  }
  & * {
    border-radius: 0;
    outline: none;
  }
  &:focus * {
    border-radius: 0;
    border-radius: 0;
  }
  & label {
    display: none !important;
  }
}

.searchSection {
}

.searchInput {
  & * {
    border-radius: 0;
  }
  & label {
    display: none !important;
  }
}
