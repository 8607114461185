.wrapper {
  width: 100%;
  margin-bottom: 8px;
  min-width: 600px;
  margin-top: -40px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;

    .inputWrapper {
      max-width: 315px;
      margin-left: 40px;
      > div {
        input {
          border: 1px solid #ccc !important;
        }
      }
    }
    button {
      max-height: 60px;
      margin-top: 16px;
    }
  }

  ul {
    overflow-y: auto;
    max-height: 350px;

    li {
      display: flex;
      margin-bottom: 16px;
      margin-right: 16px;
      align-items: center;
      cursor: pointer;
      > div {
        display: flex;
      }

      img {
        min-width: 60px;
      }
      h5 {
        margin-left: 18px;
        color: black;
      }
      svg {
        cursor: pointer;
      }
    }
  }
}

.button {
  display: flex;
  justify-content: flex-end;

  > button {
    max-width: 200px;
    margin: 0 !important;
  }
}
