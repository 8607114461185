@import '../../styles/variables/colors';
@import '../../styles/variables/common';

.CustomImage {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $gray-1;
  border-radius: $border-radius;
  font-size: 1.5rem;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: $border-radius;
  }
}

.round {
  border-radius: 50% !important;
}

.bgWhite {
  background-color: $white;
  color: $gray-2;
}

.bgGray {
  background-color: $gray-1;
  color: $white;
}
