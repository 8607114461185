@import '../../styles/variables/grid';

.wrapperContent {
  flex: 0 !important;
  @media screen and (max-width: 500px) {
    margin-bottom: 10px !important;
  }
}
.wrapperCloseTitle {
  .questionCloseTitleWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 10px;
    background: var(--primary-bg-trans-15);
    padding: 10px 0;
    width: 100vw;
    margin-left: 50%;
    transform: translateX(-50%);
    & > p {
      width: 100%;
      text-align: left;
      text-transform: uppercase;
      color: #828ecc;
      font-size: 20px;
      line-height: 25px;
      max-width: 1080px;
      padding: 0 20px;
      margin: 0;
      @media screen and (max-width: 764px) {
        padding: 0 10px;
      }
    }
  }
}
.actionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  @media #{$breakpoint-md-up} {
    display: none;
  }
}

.imageWrapper {
  width: 100%;
  height: 300px;
  & > img {
    object-fit: contain;
    object-position: center;
    width: 100%;
    height: 100%;
    cursor: zoom-in;
  }
}

.votesWrapper {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: var(--primary-bg-trans-15);
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);
  padding: 30px 0;
}
