.itemCard {
  background-color: transparent;
  border: none;
  padding: 0;
  display: flex;
  flex-direction: column;

  position: relative;
  width: 100%;
  height: 100%;
  // border-radius: 15px;
  overflow: hidden;
  background: var(--primary-bg-trans-15);
  z-index: 0;
  transition: all 0.3s ease;
  &:hover {
    opacity: 1;
    transform: scale(1.02);
  }
  .innerContentWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transition: all 0.3s ease;
    min-height: 250px;
    height: 100%;
    width: 100%;
    @media screen and (max-width: 600px) {
      min-height: 220px !important;
    }
    .image {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: top;
      z-index: -1;
    }
    @media screen and (max-width: 600px) {
      min-height: 250px;
      height: 100%;
    }

    .innerContent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background: linear-gradient(92.86deg, rgb(54 55 68 / 90%) 3.9%, rgb(0 0 0 / 66%) 96.49%);
      width: 100%;
      max-height: 80%;
      height: fit-content;

      overflow-y: auto;
      padding: 25px;

      h3 {
        text-transform: none;
      }
      @media screen and (max-width: 1500px) {
        padding: 15px;
      }

      @media screen and (max-width: 600px) {
        padding: 10px;
      }
    }
    h3 {
      color: #ffffff;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    span {
      color: #ffffff;
      font-size: 15px;
      margin: 10px 0;
    }
    time {
      color: #ffffff;
      font-size: 13px;
      text-transform: uppercase;
      @media screen and (max-width: 600px) {
        font-size: 12px;
      }
    }
  }
  .dropDownContentWrapper {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    min-height: 150px;
    height: 100%;
    width: 100%;
    @media screen and (max-width: 600px) {
      padding: 10px;
      // min-height: 110px;
    }
    h3 {
      color: black;
      text-transform: none;
    }
    span {
      color: black;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      word-break: break-word;
      @media screen and (max-width: 767px) {
        font-size: 11px;
      }
    }
  }
}
