@import 'shared/styles/variables/colors';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.select {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: nowrap;
  flex-direction: row;
}

.option {
  cursor: pointer;
  border-bottom: 3px solid $gray-2;
  width: 100%;
  text-align: center;
  min-width: max-content;

  &:hover {
    p {
      transform: scale(1.1);
    }
  }
}

.active {
  color: $primary;
  border-bottom: 3px solid $primary;

  p {
    color: $primary;
  }
}

.disabled {
  cursor: auto;
  opacity: 0.7;
}
