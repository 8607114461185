@import '/src/styles/variables/colors';

.wrapper {
  display: flex;
  flex-direction: column;
  width: 80vw;
  max-height: 80vh;
  overflow: scroll;
  gap: 20px;

  @media only screen and (max-width: 460px) {
    width: 100%;
  }
}

.map {
  display: flex;
  width: 100%;
  aspect-ratio: 1.6;
  overflow: hidden;
}

.google {
  display: flex;
  gap: 5px;
  align-items: center;

  &:hover {
    opacity: 1;
  }
}

.text {
  color: black;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;

  &:hover {
    color: $primary;
  }
}
