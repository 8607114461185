@import '../../styles/variables/grid';

.header {
  grid-area: header;
  display: grid;
  align-items: center;

  h3 {
    padding-left: 25px;
    font-weight: 500;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

.TaskScreenDescription {
  display: flex;
  padding-top: 32px;
  padding-bottom: 32px;
  display: none;
  flex-direction: column;
  width: 30%;
  max-width: 400px;
  padding: 8px;
  margin-top: 80px;
  @media #{$breakpoint-md-up} {
    display: flex;
  }
  > div {
    display: flex;
    align-items: center;
    min-width: 50%;
    margin-bottom: 16px;

    > span {
      font-weight: 600;
      padding-right: 8px;
      font-size: 18px;
    }
    > div {
      margin-right: 8px;
    }
  }
}

.description {
  word-break: break-word;
}

.info {
  display: flex;
  padding-top: 8px;
  padding-bottom: 32px;
  flex-wrap: wrap;

  @media #{$breakpoint-md-up} {
    display: none;
  }

  > div {
    display: flex;
    align-items: center;
    min-width: 50%;
    margin-bottom: 16px;

    > span {
      font-weight: 600;
      padding-right: 8px;
      font-size: 18px;
    }
    > div {
      margin-right: 8px;
    }
  }
}

.section {
  margin: 20px 0;

  .progressBar {
    padding-right: 25px;
  }

  .commentsGroup {
    display: flex;
    flex-direction: column;

    .comment {
      display: flex;
      align-items: center;
      margin-bottom: 32px;

      .commentContent {
        margin-left: 16px;

        .commentAuthor {
          font-weight: 500;
          margin-right: 16px;
        }
        .commentDate {
          font-size: 15px;
          line-height: 30px;
          color: #4455ea;
        }
      }
    }
  }

  .checkboxesGroup {
    display: flex;
    flex-direction: column;

    span::before {
      border-radius: 30%;
    }
  }

  .avatarGroup {
    display: flex;

    .avatar {
      margin-right: 8px;
    }
  }

  > p {
    font-size: 25px;
    line-height: 35px;
    /* identical to box height, or 140% */

    display: flex;
    align-items: center;
    text-transform: uppercase;
  }
}
.actionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  @media #{$breakpoint-md-up} {
    display: none;
  }
}

.imageWrapper {
  width: 100%;
  height: 400px;
  & > img {
    object-fit: contain;
    object-position: center;
    width: 100%;
    height: 100%;
    cursor: zoom-in;
  }
}

.wrapperSectionTitle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 1080px;
  padding: 0 20px;
  margin: 0;
  & > p {
    width: auto !important;
    margin-left: 10px !important;
    color: white !important;
    background: #828ecc !important;
    padding: 5px !important;
    border-radius: 10px !important;
  }
  & > span {
    width: 15px;
    height: 1.5px;
    background-color: #828ecc;
    margin-left: 10px;
  }
  & > a {
    line-height: 25px;
    font-size: 20px;
    text-transform: uppercase;
  }
}

.withoutSuggestion {
  & > p {
    color: #828ecc !important;
    background: transparent !important;
    padding: 0 !important;
    margin-left: 0 !important;
  }
}
