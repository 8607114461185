@import 'styles/variables/colors';

.wrapper {
  display: flex;
  position: relative;
}

.helper {
  position: absolute;
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  z-index: 2;
  background-color: $white;
  top: -2px;
  left: 20px;
  padding: 0 5px;
  border-radius: 5px;
  display: flex;
  height: 4px;
  align-items: center;
}

.emojiWrapper {
  position: absolute;
  right: auto;
  top: 0;
  z-index: 3;
}
