@import 'shared/styles/variables/colors';

.wrapper {
  width: 100%;
  max-width: 700px;
  display: flex;
  gap: 40px;
  flex-direction: column;
  justify-content: space-around;
  padding: 20px;

  @media only screen and (max-width: 740px) {
    max-width: 360px;
  }
}

.buttons {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.button {
  margin: 0;
}

.checkBox {
  margin-top: 0;
  margin-bottom: 0;
  height: 100%;
}
