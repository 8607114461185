.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 20px;

  div {
    &:hover {
      transform: scale(1);
    }
  }
}

.mobileWrapper {
  padding: 10px;
}

.title {
  display: flex;
  align-items: flex-start;
  gap: 10px;

  .titleTextDesc {
    width: fit-content;
    margin: 0;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-size: 35px;
    line-height: 35px;

    text-transform: uppercase;

    color: #171719;
  }

  .controls {
    display: flex;
    margin: auto 0;
  }

  .tab {
    width: 350px;
    margin-left: auto;
  }
}

.mobile {
  flex-wrap: wrap;

  .tab {
    width: 100%;
    margin: 0;
  }

  .controls {
    margin-left: auto;
  }
}

.button {
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  align-items: center;
  justify-content: center;

  path {
    stroke-width: 3;
  }

  &:hover {
    transform: scale(1.1);
  }

  &:disabled {
    cursor: auto;
    opacity: 0.5;

    &:hover {
      transform: scale(1);
    }
  }
}
